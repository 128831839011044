import { DEFAULT_REQUEST_OPTIONS } from '../../../common/requestParameter';
import { wmServerAddress } from '../../../common/MetaSetting';
import { UIGroupShape, GroupSyncRequestShape, UIShareShape, UIShareInfoShape, UIShareStatusShape, ShareSyncRequestShape } from '../../../model/MemoModelShapes';
import { SHARE_SETTING_TYPE_MARK } from '../../../common/MetaData';
import { fetchHttpResponseHandler, fetchHttpExceptionHandler } from '../../../util/UtilFunction';

export function fetchGroupShares(oidcFetch: Function, lastSyncTimestamp = 0) {
  return oidcFetch(`${wmServerAddress}/share/groups/${lastSyncTimestamp}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchGroups(oidcFetch: Function, lastSyncTimestamp = 0) {
  // console.log("group lastSyncTimestamp", lastSyncTimestamp);
  return oidcFetch(`${wmServerAddress}/group/all/${lastSyncTimestamp}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchGroup(oidcFetch: Function, groupId: string) {
  return oidcFetch(`${wmServerAddress}/group/${groupId}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchGroupShare(oidcFetch: Function, groupId: string) {
  return oidcFetch(`${wmServerAddress}/share/group/${groupId}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function remoteGroupShareSync(oidcFetch: Function, group: UIShareShape) {
  let shareInfo: UIShareInfoShape[] = [];
  if (group.isPublic)
    shareInfo.push({
      targetType: SHARE_SETTING_TYPE_MARK.PUBLIC,
      targetId: ''
    });
  group.targetUserGroupIds.map((userGroup) => {
    shareInfo.push({
      targetType: SHARE_SETTING_TYPE_MARK.USER_GROUP,
      targetId: userGroup.id
    });
  });
  group.targetUserIds.map((user) => {
    shareInfo.push({
      targetType: SHARE_SETTING_TYPE_MARK.USER,
      targetId: user.id
    });
  });
  let shareStatus: UIShareStatusShape = {
    guid: group.guid,
    createTimestamp: group.createTimestamp,
    modifyTimestamp: group.modifyTimestamp,
    shareStatus: shareInfo
  };
  var groupSyncRequest: ShareSyncRequestShape = {
    syncType: 0,
    guid: group.guid,
    sourceShare: shareStatus
  };

  var myInit = Object.assign({}, DEFAULT_REQUEST_OPTIONS, {
    method: 'PUT',
    body: JSON.stringify(groupSyncRequest)
  });

  return oidcFetch(`${wmServerAddress}/share/group/sync`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function remoteGroupSync(oidcFetch: Function, group: UIGroupShape) {
  var groupSyncRequest: GroupSyncRequestShape = {
    syncType: 0,
    GUID: group.guid,
    sourceGroup: group
  };

  var myInit = Object.assign({}, DEFAULT_REQUEST_OPTIONS, {
    method: 'PUT',
    body: JSON.stringify(groupSyncRequest)
  });

  return oidcFetch(`${wmServerAddress}/group/sync`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}
