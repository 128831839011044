import React, { useState, useEffect } from 'react';

import { Box, Tabs, Tab, FormControl, TextField, Select, MenuItem, SelectChangeEvent, IconButton, Chip, Divider } from '@mui/material';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { selectCurrentMemo, selectMemoShares, selectCurrentMemoTags, updateMemoAsync, resetEditMemoContent, selectCurrentMemoTrainings } from './memoSlice';
import { selectGroups, changeSelectedGroupId } from '../group/groupSlice';
import { changeSelectedSectionId } from '../section/sectionSlice';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoopIcon from '@mui/icons-material/Loop';
import EditNoteIcon from '@mui/icons-material/EditNote';

import { useTranslation } from 'react-i18next';

import { MemoEdit } from './MemoEdit';
import { Tags } from './Tags';
import { ViewList } from './ViewList';

import { findOneWMObjectFromAll, isEmptyOrSpaces, triggerEnterKeyFunction } from '../../../common/Utility';
import { DEFAULT_GROUP_GUID, editTopStatusHeight, hostServerAddress, mainContentMarginTopSecondary, maxLengthMemoTitle } from '../../../common/MetaSetting';
import { Article, Group, Person, Public } from '@mui/icons-material';
import { UIShareShape } from '../../../model/MemoModelShapes';
import { ROUTES_LIST } from '../../constants';
import { useOidcFetch } from '@axa-fr/react-oidc';
import { useTheme } from '@emotion/react';
import { MemoStatus } from '../../../common/MetaData';
import { StyledBadge } from '../../shared/ShareMarker';
import { Trainings } from './Trainings';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} style={{ paddingTop: '0.1em', height: '100%' }}>
      {value === index && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'background.paper',
            borderTopLeftRadius: '0.5em',
            borderTopRightRadius: '0.5em'
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: {
      minHeight: '2em',
      borderBottomLeftRadius: '0.5em',
      borderBottomRightRadius: '0.5em',
      height: '2.5em',
      backgroundColor: 'secondary.light',
      color: 'secondary.dark',
      '&.Mui-selected': {
        backgroundColor: 'primary.light',
        color: 'primary.main',
        fontWeight: 'bolder'
      },
      '&:hover': {
        backgroundColor: 'primary.light',
        color: 'primary.main'
      }
    }
  };
}

export function MemoPanel(props: any) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { fetch } = useOidcFetch();

  const currentMemo: any = useAppSelector(selectCurrentMemo);
  const [memoObject, setMemoObject] = useState(currentMemo);
  const memoShareSettings: any = useAppSelector(selectMemoShares);
  const groups = useAppSelector(selectGroups);

  let representations = '';
  let representationObjects: any[] = [];

  if (memoObject.representations && memoObject.representations.length > 0) {
    representations = memoObject.representations[0].content;

    try {
      representationObjects = JSON.parse(representations);
    } catch (error) {
      console.log('representationObjects parse', error);
    }
  }

  const selectedMemoTags: any = useAppSelector(selectCurrentMemoTags);
  const [tag, setTag] = useState<any>();
  useEffect(() => {
    if (selectedMemoTags && selectedMemoTags.tag) {
      try {
        setTag(JSON.parse(selectedMemoTags.tag.content));
      } catch (error) {
        console.log('tag parse', error);
      }
    } else {
      setTag({});
    }
  }, [selectedMemoTags]);

  const selectedMemoTrainings: any = useAppSelector(selectCurrentMemoTrainings);
  const [trainings, setTrainings] = useState<any[]>();
  useEffect(() => {
    if (selectedMemoTrainings && selectedMemoTrainings.training) {
      try {
        setTrainings(JSON.parse(selectedMemoTrainings.training.content));
      } catch (error) {
        console.log('trainings parse', error);
      }
    } else {
      setTrainings([]);
    }
  }, [selectedMemoTrainings]);

  const [tabIndex, setTabIndex] = useState(0);
  const [updateStatus, setUpdateStatus] = useState<number>(currentMemo?.updateStatus);
  const [editTitle, setEditTitle] = useState<string>(currentMemo?.title);
  const [memoGroupId, setMemoGroupId] = useState<string>(currentMemo?.groupId);
  const [shareSetting, setShareSetting] = useState<UIShareShape | undefined>();

  useEffect(() => {
    setMemoObject(currentMemo);
    setUpdateStatus(currentMemo?.updateStatus);
    setEditTitle(currentMemo?.title);
    setMemoGroupId(currentMemo?.groupId);
  }, [currentMemo]);

  useEffect(() => {
    let share = memoShareSettings.filter((setting: any) => setting.guid === currentMemo?.guid)[0];
    setShareSetting(share);
  }, [currentMemo, memoShareSettings]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) dispatch(resetEditMemoContent(currentMemo?.guid));
    // else dispatch(resetEditMemoContent(''));
    setTabIndex(newValue);
  };

  const handleMemoTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditTitle(event.target.value);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    updateTitle();
  };

  const updateTitle = () => {
    if (isEmptyOrSpaces(editTitle) || memoObject.title === editTitle) {
      return;
    }

    if (memoObject) dispatch(updateMemoAsync({ oidcFetch: fetch, memo: Object.assign({}, memoObject, { title: editTitle }) }));
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    triggerEnterKeyFunction(event, updateTitle);
  };

  const handleGroupChange = (event: SelectChangeEvent) => {
    if (!event.target.value || memoGroupId == event.target.value) {
      return;
    }

    if (memoObject)
      dispatch(
        updateMemoAsync({
          oidcFetch: fetch,
          memo: Object.assign({}, memoObject, {
            groupId: event.target.value
          })
        })
      );

    let selectedGroup = findOneWMObjectFromAll(groups, event.target.value);
    if (selectedGroup) {
      dispatch(changeSelectedGroupId(selectedGroup.guid));
      dispatch(changeSelectedSectionId(selectedGroup.sectionId));
    }
  };

  if (!memoObject) return <></>;

  if (tabIndex === 1 && !(tag && tag.category === 'key')) setTabIndex(0);

  if (tabIndex === 2 && !trainings) setTabIndex(0);

  if (tabIndex === 3 && !(representationObjects && representationObjects.length > 0)) setTabIndex(0);

  const openInNewTab = () => {
    window.open(`${hostServerAddress}${ROUTES_LIST.memo}/${memoObject.guid}`);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'stretch',
        width: '100%',
        height: `calc(100vh - ${mainContentMarginTopSecondary})`,
        padding: '0.2em',
        backgroundColor: 'secondary.light'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: editTopStatusHeight
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center'
          }}
        >
          {updateStatus === MemoStatus.UPDATED && <Chip size="small" icon={<CheckCircleIcon color="success" />} label={t('currentMemoStatusUpdated')} />}
          {updateStatus === MemoStatus.UPDATING && <Chip size="small" icon={<LoopIcon color="primary" />} label={t('currentMemoStatusUpdating')} />}
          {updateStatus === MemoStatus.NOTUPDATE && <Chip size="small" icon={<EditNoteIcon color="warning" />} label={t('currentMemoStatusNotUpdate')} />}
        </Box>
        <Box
          sx={{
            flexGrow: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          <IconButton color="primary" onClick={openInNewTab}>
            <Article />
          </IconButton>
          <Divider orientation="vertical" flexItem></Divider>
          <Public
            sx={{
              marginLeft: 1,
              marginRight: 1,
              color: shareSetting?.isPublic ? 'primary.main' : 'secondary.main'
            }}
          ></Public>
          {shareSetting?.targetUserGroupIds && shareSetting?.targetUserGroupIds.length > 0 ? (
            <StyledBadge badgeContent={shareSetting?.targetUserGroupIds.length} color="primary" max={99}>
              <Group sx={{ marginLeft: 1, marginRight: 1, color: 'primary.main' }} />
            </StyledBadge>
          ) : (
            <Group sx={{ marginLeft: 1, marginRight: 1, color: 'secondary.main' }} />
          )}
          {shareSetting?.targetUserIds && shareSetting?.targetUserIds.length > 0 ? (
            <StyledBadge badgeContent={shareSetting?.targetUserIds.length} color="primary" max={99}>
              <Person sx={{ marginLeft: 1, marginRight: 1, color: 'primary.main' }} />
            </StyledBadge>
          ) : (
            <Person sx={{ marginLeft: 1, marginRight: 1, color: 'secondary.main' }} />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box sx={{ flexGrow: 1, borderRadius: '0.5em' }}>
          <FormControl sx={{ width: '100%' }} size="small">
            <TextField
              id="editMemoTitle"
              type="text"
              fullWidth
              value={editTitle}
              inputProps={{ maxLength: maxLengthMemoTitle }}
              InputProps={{ sx: { borderRadius: '0.5em', backgroundColor: 'background.paper' } }}
              onChange={handleMemoTitleChange}
              onKeyDown={handleKeyPress}
              onBlur={handleBlur}
              sx={{ backgroundColor: 'transparent' }}
            />
          </FormControl>
        </Box>
        <Box sx={{ flexGrow: 0, width: '250px' }}>
          <FormControl sx={{ width: '100%', borderRadius: '0.5em', backgroundColor: 'background.paper' }} size="small">
            <Select labelId="memo-groupId-label" id="memo-groupId" value={memoGroupId} onChange={handleGroupChange} sx={{ borderRadius: '0.5em', backgroundColor: 'transparent' }}>
              <MenuItem dense key={DEFAULT_GROUP_GUID} value={DEFAULT_GROUP_GUID} disabled={DEFAULT_GROUP_GUID === memoGroupId}>
                {t('optionDefault')}
              </MenuItem>
              {groups
                .filter((g) => g.stateCode !== 0)
                .map((g) => (
                  <MenuItem dense key={g.guid} value={g.guid} disabled={g.guid === memoGroupId}>
                    {g.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <CustomTabPanel value={tabIndex} index={0}>
        <MemoEdit />
      </CustomTabPanel>
      {tag && tag.category === 'key' && (
        <CustomTabPanel value={tabIndex} index={1}>
          <Tags tags={tag.tags}></Tags>
        </CustomTabPanel>
      )}
      {trainings && (
        <CustomTabPanel value={tabIndex} index={2}>
          <Trainings trainings={trainings}></Trainings>
        </CustomTabPanel>
      )}
      {representationObjects && representationObjects.length > 0 && (
        <CustomTabPanel value={tabIndex} index={3}>
          {/* {representationObjects && targetIdentity === "196730" && <EventList events={representationObjects} ></EventList>}
        {representationObjects && targetIdentity === "34" && <AccountList accounts={representationObjects} ></AccountList>} */}
          {/* {representationObjects && <AccountList accounts={[representationObjects]} ></AccountList>} */}
          <ViewList sources={representationObjects}></ViewList>
        </CustomTabPanel>
      )}
      <Box>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          sx={{ minHeight: '2em', height: '2em' }}
          // textColor="primary"
          // indicatorColor="primary"
          TabIndicatorProps={{
            style: {
              top: 0
            }
          }}
        >
          <Tab label={t('memoTypeText')} {...a11yProps(0)} />
          {tag && tag.category === 'key' && <Tab label={t('memoTypeTag')} {...a11yProps(1)} />}
          {/* {trainings && <Tab label={t('memoTypeTraining')} {...a11yProps(2)} />} */}
          {representationObjects && representationObjects.length > 0 && <Tab label={t('memoTypeInstance')} {...a11yProps(3)} />}
        </Tabs>
      </Box>
    </Box>
  );
}
