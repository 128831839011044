import React, { useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { useOidc } from '@axa-fr/react-oidc';

import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import {
  IconButton,
  Menu,
  Avatar,
  Tooltip,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Drawer,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  Divider,
  Typography,
  List,
  Toolbar,
  Box,
  BadgeProps,
  Badge,
  Button
} from '@mui/material';

import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, Notifications as NotificationsIcon } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectCurrentLanguage, changeCurrentLanguage, selectMessages, removeMessageFromLocal } from '../../appSlice';
import { selectCurrentUser } from '../components/user/userSlice';
import { DEFAULT_LANGUAGE, hostServerAddress, userServerAddress, mainHeaderHeight, drawerWidth, drawerHeaderMinHeight, toolBarPaddingLeftRight } from '../../common/MetaSetting';

import { LIB_MENU_LIST, MENU_LIST, SYSTEM_MENU_LIST } from '../constants';
import { changeSelectedMemoId } from '../components/memo/memoSlice';

import { Outlet } from 'react-router-dom';
import { LayoutMain } from './LayoutMain';
import { MessageType, MessageShape } from '../../model/AppUIShapes';
import { useSnackbar } from 'notistack';

const leftDrawerWidth = drawerWidth;
const headerHeight = mainHeaderHeight;

const openedMixin = (theme: Theme): CSSObject => ({
  width: leftDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)})`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)})`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: leftDrawerWidth,
    width: `calc(100% - ${leftDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: leftDrawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

let messageAppendTimer: any;
let legacyMessages: MessageShape[] = [];

export const MLayout = () => {
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const currentUser = useAppSelector(selectCurrentUser);

  const { logout } = useOidc();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const [currentLang, setCurrentLang] = useState(DEFAULT_LANGUAGE);

  const { enqueueSnackbar } = useSnackbar();

  const messages = useAppSelector(selectMessages);

  useEffect(() => {
    triggerNoticficationByMessages(messages);
  }, [messages]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout(hostServerAddress);
  };

  const handleLanguageChange = (event: React.MouseEvent<HTMLElement>, newAlignment: any) => {
    setCurrentLang(newAlignment);
    dispatch(changeCurrentLanguage(newAlignment));
    i18n.changeLanguage(newAlignment === 'zhCN' ? 'zh-CN' : 'en-US');
    dispatch(changeSelectedMemoId(''));
  };

  const handleNoticationsClose = (messageId: string) => {
    dispatch(removeMessageFromLocal(messageId));
  };

  const triggerNoticficationByMessages = (messageList: MessageShape[]) => {
    messageList.forEach((msg) => {
      let foundMessages = legacyMessages.filter((lm) => lm.guid === msg.guid);
      if (foundMessages.length < 1) {
        legacyMessages = [...legacyMessages, msg];
        clearTimeout(messageAppendTimer);
        messageAppendTimer = setTimeout(() => (legacyMessages = []), 30000);
        enqueueSnackbar(t(msg.message), {
          variant: convertMessageType(msg),
          autoHideDuration: 6000,
          onClose: () => handleNoticationsClose(msg.guid),
          anchorOrigin: { vertical: 'top', horizontal: 'center' }
        });
      }
    });
  };

  const convertMessageType = (message: MessageShape) => {
    console.log('message.messageType', message.messageType);
    switch (message.messageType) {
      case MessageType.SUCCESS:
        return 'success';
      case MessageType.INFO:
        return 'info';
      case MessageType.WARNING:
        return 'warning';
      case MessageType.ERROR:
        return 'error';
      default:
        return 'default';
    }
  };

  const { pathname } = useLocation();

  let mainTitle = '';
  MENU_LIST.map((mi, index) => {
    if (pathname === mi.route) mainTitle = t(mi.literal);
  });
  // SYSTEM_MENU_LIST.map((mi, index) => {
  //   if (pathname === mi.route) mainTitle = t(mi.literal);
  // });
  LIB_MENU_LIST.map((mi, index) => {
    if (pathname === mi.route) mainTitle = t(mi.literal);
  });

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open} sx={{ backgroundColor: 'secondary.light', color: 'primary.main' }}>
        <Toolbar variant="dense" sx={{ margin: 0, paddingLeft: toolBarPaddingLeftRight as any, paddingRight: toolBarPaddingLeftRight as any }}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                flexGrow: 0,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center'
              }}
            >
              <IconButton
                color="inherit"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  color: 'inherit',
                  ...(open && { display: 'none' })
                }}
              >
                <MenuIcon sx={{ color: 'primary.main' }} />
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                  alignItems: 'center',
                  color: 'primary.main'
                }}
              >
                {mainTitle}
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            ></Box>
            <Box
              sx={{
                flexGrow: 0,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'end',
                  paddingLeft: theme.spacing(1),
                  paddingRight: theme.spacing(1)
                }}
              >
                <Box sx={{ fontSize: '1em' }}>{currentUser?.userName}</Box>
                <ToggleButtonGroup value={currentLanguage} exclusive onChange={handleLanguageChange} size="small" color="primary">
                  <ToggleButton
                    value="zhCN"
                    sx={{
                      height: '1.5em',
                      color: 'secondary.main',
                      '&.Mui-selected': {
                        backgroundColor: 'primary.light',
                        color: 'primary.main',
                        fontWeight: 'bolder'
                      },
                      '&:hover': {
                        backgroundColor: 'primary.light',
                        color: 'primary.main'
                      }
                    }}
                  >
                    {t('langZh')}
                  </ToggleButton>
                  <ToggleButton
                    value="enUS"
                    sx={{
                      height: '1.5em',
                      color: 'secondary.main',
                      '&.Mui-selected': {
                        backgroundColor: 'primary.light',
                        color: 'primary.main',
                        fontWeight: 'bolder'
                      },
                      '&:hover': {
                        backgroundColor: 'primary.light',
                        color: 'primary.main'
                      }
                    }}
                  >
                    {t('langEn')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Tooltip title={t('tipClickToOpenUserMenu')}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={currentUser?.userName}
                    src={currentUser && currentUser.avatarPath && `${userServerAddress}${currentUser?.avatarPath}`}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: { headerHeight } }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem dense key="logoutItem" onClick={handleLogout}>
                  <Typography textAlign="center" component="span">
                    {t('buttonLogout')}
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <CustomDrawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{
            backgroundColor: 'secondary.light',
            color: 'primary.main',
            minHeight: drawerHeaderMinHeight as any
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              height: `${headerHeight}`,
              padding: '5px',
              borderRadius: '0.5em',
              bgcolor: 'background.paper'
            }}
          >
            <Avatar src="/logo192.png" />
            {currentLang === DEFAULT_LANGUAGE ? (
              <Box>
                <span style={{ color: theme.palette.primary.main }}>
                  {t('wordPrefixWise')}
                  {t('wordLeftWise')}
                </span>
                <span style={{ color: theme.palette.secondary.main }}>
                  {t('wordPrefixMemo')}
                  {t('wordLeftMemo')}
                </span>
              </Box>
            ) : (
              <Box>
                <span style={{ color: theme.palette.primary.main }}>{t('wordPrefixWise')}</span>
                <span style={{ color: theme.palette.secondary.main }}>{t('wordLeftWise')}</span>
                <span style={{ color: theme.palette.primary.main }}>{t('wordPrefixMemo')}</span>
                <span style={{ color: theme.palette.secondary.main }}>{t('wordLeftMemo')}</span>
              </Box>
            )}
          </Box>
          <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        </DrawerHeader>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            width: '100%',
            height: `calc(100vh - ${mainHeaderHeight})`,
          }}
        >
          <Box>
            <List dense>
              {MENU_LIST.map((mi, index) => (
                <Link key={mi.literal} to={mi.route}>
                  <ListItem
                    key={mi.literal}
                    disablePadding
                    selected={pathname === mi.route}
                    sx={{
                      display: 'block',
                      color: 'secondary.main',
                      '&.Mui-selected': {
                        backgroundColor: 'primary.light',
                        color: 'primary.main',
                        fontWeight: 'bolder'
                      },
                      '&:hover': {
                        backgroundColor: 'primary.light',
                        color: 'primary.main'
                      }
                    }}
                    onClick={() => { }}
                    dense
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        color: 'inherit'
                      }}
                      dense
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : 'auto',
                          justifyContent: 'center',
                          color: 'inherit'
                        }}
                      >
                        <mi.Icon />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ fontWeight: 'inherit', fontSize: '1em' }} primary={t(mi.literal)} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
            <Divider />
            <List dense>
              {SYSTEM_MENU_LIST.map((mi, index) => (
                <Link key={mi.literal} to={mi.route}>
                  <ListItem
                    key={mi.literal}
                    disablePadding
                    selected={pathname === mi.route}
                    sx={{
                      display: 'block',
                      color: 'secondary.main',
                      '&.Mui-selected': {
                        backgroundColor: 'primary.light',
                        color: 'primary.main',
                        fontWeight: 'bolder'
                      },
                      '&:hover': {
                        backgroundColor: 'primary.light',
                        color: 'primary.main'
                      }
                    }}
                    onClick={() => { }}
                    dense>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        color: 'inherit'
                      }}
                      dense>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : 'auto',
                          justifyContent: 'center',
                          color: 'inherit'
                        }}>
                        <mi.Icon />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ fontWeight: 'inherit', fontSize: '1em' }} primary={t(mi.literal)} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
            {/* <Divider />
            <List dense>
              {LIB_MENU_LIST.map((mi, index) => (
                <Link key={mi.literal} to={mi.route}>
                  <ListItem
                    key={mi.literal}
                    disablePadding
                    selected={pathname === mi.route}
                    sx={{
                      display: 'block',
                      color: 'secondary.main',
                      '&.Mui-selected': {
                        backgroundColor: 'primary.light',
                        color: 'primary.main',
                        fontWeight: 'bolder'
                      },
                      '&:hover': {
                        backgroundColor: 'primary.light',
                        color: 'primary.main'
                      }
                    }}
                    onClick={() => { }}
                    dense
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        color: 'inherit'
                      }}
                      dense
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : 'auto',
                          justifyContent: 'center',
                          color: 'inherit'
                        }}
                      >
                        <mi.Icon />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ fontWeight: 'inherit', fontSize: '1em' }} primary={t(mi.literal)} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List> */}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: `${mainHeaderHeight}`,
            }}
          >
            <Typography sx={{ opacity: open ? 1 : 0 }}>{t('appVersion')}</Typography>
          </Box>
        </Box>
      </CustomDrawer>
      <LayoutMain>
        <Outlet />
      </LayoutMain>
    </Box>
  );
};
