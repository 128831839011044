import React, { useState, useEffect } from 'react';

import { Box, TextField, Stack, Paper, useTheme, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';

import { useTranslation } from 'react-i18next';

import { isEmptyOrSpaces, triggerEnterKeyFunction } from '../../../common/Utility';
import { searchByKeyAsync, selectSearchKey, selectSearchMemos } from './searchSlice';
import { useNavigate } from 'react-router-dom';
import { ROUTES, ROUTES_LIST } from '../../constants';
import { changeSelectedMemoId } from '../memo/memoSlice';
import { changeSelectedGroupId, changeSelectedPathByGroupIdAsync } from '../group/groupSlice';
import { changeCurrentListPanel, changeCurrentViewPanel } from '../../../appSlice';
import { MEMO_PAGE_LIST_PANEL, MEMO_PAGE_VIEW_PANEL } from '../../../common/MetaData';

const MemoItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  padding: '1em',
  maxWidth: '220px',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    cursor: 'pointer'
  }
}));

export function SearchPanel(props: any) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const nvaigate = useNavigate();

  const keyString = useAppSelector(selectSearchKey);
  const [searchKey, setSearchKey] = useState(keyString);

  useEffect(() => {
    setSearchKey(keyString);
  }, [keyString]);

  const memosFound = useAppSelector(selectSearchMemos);
  const [filteredMemos, setFilteredMemos] = useState(memosFound);

  useEffect(() => {
    setFilteredMemos(memosFound);
  }, [memosFound]);

  const handleEditStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const updateMemosFoundFilter = () => {
    if (isEmptyOrSpaces(searchKey)) {
      setSearchKey('');
      return;
    }

    dispatch(searchByKeyAsync(searchKey));
    // setEditString("");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    triggerEnterKeyFunction(event, updateMemosFoundFilter);
  };

  const handleMemoItemClick = (memoId: string, memoGroupId: string) => {
    dispatch(changeCurrentListPanel(MEMO_PAGE_LIST_PANEL.MEMO));
    dispatch(changeCurrentViewPanel(MEMO_PAGE_VIEW_PANEL.EDIT));
    dispatch(changeSelectedPathByGroupIdAsync(memoGroupId));
    dispatch(changeSelectedGroupId(memoGroupId));
    dispatch(changeSelectedMemoId(memoId));
    nvaigate(ROUTES_LIST.edit);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'stretch'
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          {filteredMemos &&
            filteredMemos.map((memoFound) => (
              <MemoItem key={memoFound.guid} elevation={4} sx={{ '&:hover': { boxShadow: 8 }, fontWeight: 'bolder' }} onClick={() => handleMemoItemClick(memoFound.guid, memoFound.groupId)}>
                <Typography maxWidth={'200px'} noWrap>
                  {memoFound.title}
                </Typography>
                <Typography noWrap>({memoFound.hit})</Typography>
              </MemoItem>
            ))}
        </Stack>
      </Box>
      <Box
        sx={{
          flexGrow: 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0.5em'
        }}
      >
        <Box></Box>
        <TextField
          sx={{ width: '40%', borderRadius: '3em', backgroundColor: 'background.paper' }}
          value={searchKey}
          onChange={handleEditStringChange}
          onKeyDown={handleKeyPress}
          placeholder={t('searchMemoPlaceHolder')}
          inputProps={{
            style: { textAlign: 'center' }
          }}
          InputProps={{
            style: {
              borderRadius: '3em'
            }
          }}
        ></TextField>
        <Box></Box>
      </Box>
    </Box>
  );
}
