import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';
import { UIMemoShape, UIMemoIndexShape, UIMemoSearchShape } from '../../../model/MemoModelShapes';

import { SEARCH_MEMOS_BY_KEY } from '../../constants/ReduxConst';

export interface MemoState {
  tagMemosMpping: UIMemoIndexShape[];
  key: string;
  memosFound: UIMemoSearchShape[];
  status: 'idle' | 'loading' | 'failed';
  isSyncing: boolean;
}

const initialState: MemoState = {
  tagMemosMpping: [],
  key: '',
  memosFound: [],
  status: 'idle',
  isSyncing: false
};

export const searchByKeyAsync = createAsyncThunk<number, string, { state: RootState }>(SEARCH_MEMOS_BY_KEY, async (key, thunkAPI) => {
  // console.log('key', key);
  thunkAPI.dispatch(updateKey(key));
  // console.log('thunkAPI.getState().search.tagMemosMpping', thunkAPI.getState().search.tagMemosMpping);
  var tagMemosList = thunkAPI.getState().search.tagMemosMpping.filter((t) => t.tag === key);
  // console.log('tagMemosList', tagMemosList);
  let newMemosFound: UIMemoSearchShape[] = [];
  if (tagMemosList && tagMemosList.length > 0) {
    tagMemosList.map((tagMemos: any) => {
      // console.log('tagMemos', tagMemos);
      tagMemos.memos.map((tagMemo: any) => {
        // console.log('tagMemo', tagMemo);
        let found = false;
        newMemosFound.map((newMemoFound: any) => {
          if (newMemoFound.guid === tagMemo.guid) {
            newMemoFound.hit++;
            found = true;
            return;
          }
        });
        if (!found) {
          let newMemoFound: UIMemoSearchShape = {
            userId: tagMemo.userId,
            guid: tagMemo.guid,
            title: tagMemo.title,
            createTimestamp: tagMemo.createTimestamp,
            modifyTimestamp: tagMemo.modifyTimestamp,
            stateCode: tagMemo.stateCode,
            groupId: tagMemo.groupId,
            hit: 1
          };
          newMemosFound.push(newMemoFound);
        }
      });
    });
  }
  thunkAPI.dispatch(updateMemosFound(newMemosFound.sort((a, b) => a.hit - b.hit)));

  return 0;
});

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    saveTagToLocalMapping: (state, action: PayloadAction<UIMemoShape>) => {
      if (!action.payload) return;
      let updatedMemo: UIMemoShape = action.payload;
      let tagsString = updatedMemo?.tags?.tag?.content;
      if (tagsString) {
        let tagsObject: any = JSON.parse(tagsString);
        if (tagsObject?.tags) {
          tagsObject.tags.map((tag: any) => {
            // console.log('tag(%O): %o', updatedMemo.guid, tag);
            if (state.tagMemosMpping.length === 0) {
              state.tagMemosMpping = [{ tag: tag.label, memos: [updatedMemo] }];
            } else {
              var tagMemosList = state.tagMemosMpping.filter((t) => t.tag === tag.label);
              var tagMemos: UIMemoIndexShape = tagMemosList[0];
              // console.log('tagMemos(%O): %o', updatedMemo.guid, tagMemos);
              if (tagMemos) {
                var notFoundMemos = tagMemos.memos.filter((m) => m.guid !== updatedMemo.guid);
                // console.log('notFoundMemos(%O): %o', updatedMemo.guid, notFoundMemos);
                tagMemos = Object.assign({}, tagMemos, {
                  memos: [...notFoundMemos, updatedMemo]
                });
              } else {
                tagMemos = { tag: tag.label, memos: [updatedMemo] };
              }
              var notMatchTagMemosList = state.tagMemosMpping.filter((t) => t.tag !== tag.label);
              // console.log('notMatchTagMemosList(%O): %o', updatedMemo.guid, notMatchTagMemosList);
              state.tagMemosMpping = [...notMatchTagMemosList, tagMemos];
            }
          });
        }
      }

      // console.log('state.tagMemosMpping(%o): %o', updatedMemo.guid, state.tagMemosMpping);
    },
    updateKey: (state, action: PayloadAction<string>) => {
      state.key = action.payload;
    },
    updateMemosFound: (state, action: PayloadAction<UIMemoSearchShape[]>) => {
      state.memosFound = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchByKeyAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(searchByKeyAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(searchByKeyAsync.rejected, (state) => {
        console.log('searchByKeyAsync.rejected');
        console.log('searchByKeyAsync.state', state.tagMemosMpping);
        state.isSyncing = false;
        state.status = 'failed';
      });
  }
});

export const { saveTagToLocalMapping, updateKey, updateMemosFound } = searchSlice.actions;

export const selectTagMemosMapping = (state: RootState) => state.search.tagMemosMpping;
export const selectSearchMemos = (state: RootState) => state.search.memosFound;
export const selectSearchKey = (state: RootState) => state.search.key;

export default searchSlice.reducer;
