import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';

import { SEND_TRAINING_MESSAGE, CREATE_PHRASE_ASYNC } from '../../constants/ReduxConst';
import { sendTrainingMessage } from './trainingAPI';
import { SOURCE_ID } from '../../../common/MetaSetting';
import { FetchResponseShape, UIPhraseShape } from '../../../model/MemoModelShapes';
import { CommandType, HttpResponseType, MessageType } from '../../../model/AppUIShapes';
import { appendMessageAsync } from '../../../appSlice';

export interface LocalTrainingState {
  phrases: UIPhraseShape[];
  status: 'idle' | 'loading' | 'failed';
  currentUser: any | undefined;
  users: any[];
  userMarks: any[];
}

const initialState: LocalTrainingState = {
  phrases: [],
  status: 'idle',
  currentUser: undefined,
  users: [],
  userMarks: []
};

export const savePhraseAsync = createAsyncThunk<number, UIPhraseShape, { state: RootState }>(CREATE_PHRASE_ASYNC, async (phrase, thunkAPI) => {
  let createTime = new Date().getTime();
  phrase = Object.assign(phrase, { createTimestamp: createTime, modifyTimestamp: createTime });
  thunkAPI.dispatch(savePhraseToLocal(phrase));

  return createTime;
});

export const sendTrainingMessageAsync = createAsyncThunk<number, any, { state: RootState }>(SEND_TRAINING_MESSAGE, async (param, thunkAPI) => {
  sendTrainingMessage(param.oidcFetch, CommandType.ANALYSIS, param.commandContent).then((response: FetchResponseShape) => {
    if (response.responseType === HttpResponseType.SUCCESS) {
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: response.message }));
    }
  });

  return new Date().getTime();
});

export const trainingSlice = createSlice({
  name: 'training',
  initialState,
  reducers: {
    savePhraseToLocal: (state, action: PayloadAction<UIPhraseShape>) => {
      var updatedAddedPhrases = [...state.phrases, action.payload];
      state.phrases = updatedAddedPhrases.sort((a, b) => a.createTimestamp - b.createTimestamp);
    },
    saveUserToLocal: (state, action: PayloadAction<any>) => {
      state.currentUser = Object.assign({}, action.payload);
    },
    saveUsersToLocal: (state, action: PayloadAction<any>) => {
      state.users = [...action.payload];
    },
    saveUserMarksToLocal: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        var filteredItems = state.userMarks.filter((um) => {
          var foundOne = action.payload.filter((fum: any) => {
            return fum.userId === um.userId;
          });
          return !(foundOne && foundOne.length > 0);
        });
        state.userMarks = [...filteredItems, ...action.payload];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(savePhraseAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(savePhraseAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(savePhraseAsync.rejected, (state) => {
        console.log('savePhraseAsync.rejected');
        state.status = 'failed';
      })
      .addCase(sendTrainingMessageAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendTrainingMessageAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(sendTrainingMessageAsync.rejected, (state) => {
        console.log('sendTrainingMessageAsync.rejected');
        state.status = 'failed';
      });
  }
});

export const { savePhraseToLocal, saveUserToLocal, saveUsersToLocal, saveUserMarksToLocal } = trainingSlice.actions;

export const selectPhrases = (state: RootState) => state.training.phrases;
export const selectCurrentUser = (state: RootState) => state.training.currentUser;
export const selectUsers = (state: RootState) => state.training.users;
export const selectUserMarks = (state: RootState) => state.training.userMarks;

export default trainingSlice.reducer;
