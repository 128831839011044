import React, { useState, useEffect } from 'react';

import {
  Box,
  TextField,
  Badge,
  BadgeProps,
  styled,
  Autocomplete,
  FormControlLabel,
  Typography,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  FormControl,
  Divider
} from '@mui/material';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';

import { useTranslation } from 'react-i18next';

import { ExpandMore, Group, Person, Public } from '@mui/icons-material';
import { UIUserGroupShape, UIShareTargetShape, FetchResponseShape } from '../../../model/MemoModelShapes';
import { HttpResponseType, MessageType } from '../../../model/AppUIShapes';
import { fetchUsersByKey } from '../user/userAPI';
import { ChipArray } from '../../shared/ChipArray';
import isArraiesEqualByGUID from '../../../util/UtilFunction';
import { selectCurrentUserGroup } from './userGroupSlice';
import { triggerEnterKeyFunction } from '../../../common/Utility';
import { useOidcFetch } from '@axa-fr/react-oidc';
import { appendMessageAsync } from '../../../appSlice';
import { maxLengthGroupName, retryInterval } from '../../../common/MetaSetting';

export function UserGroupPanel(props: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onUserGroupClose, onUserGroupSave, keyValue, titleValue, open, ...other } = props;

  const userGroup = useAppSelector(selectCurrentUserGroup);
  const [userGroupName, setUserGroupName] = useState(userGroup?.name);
  const [userIds, setUserIds] = useState(userGroup?.userMarks);

  const [suggestUsers, setSuggestUsers] = useState<any[]>([]);
  const [userKeyString, setUserKeyString] = useState('');
  const [userMark, setUserMark] = useState<any>();
  const [legacyUserKeyString, setLegacyUserKeyString] = useState('');

  let contentChangedTimer: any;

  const { fetch } = useOidcFetch();

  const fetchUsersByKeyRetry = (oidcFetch: Function, key: string) => {
    fetchUsersByKey(oidcFetch, key).then((userGroupResponse: FetchResponseShape) => {
      if (userGroupResponse.responseType === HttpResponseType.SUCCESS) {
        userGroupResponse.content.then((remoteUsers: any) => {
          setSuggestUsers(remoteUsers);
        });
      } else {
        dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: userGroupResponse.message }));
        setTimeout(() => fetchUsersByKeyRetry(oidcFetch, key), retryInterval);
      }
    });
  };

  const handleUserKeyStringChange = (key: string) => {
    clearTimeout(contentChangedTimer);
    setLegacyUserKeyString(key);
    fetchUsersByKeyRetry(fetch, key);
  };

  const changeOptionBaseOnValue = (value: string) => {
    var searchKey = value.trim();
    setUserKeyString(searchKey);

    clearTimeout(contentChangedTimer);
    if (searchKey) {
      if (searchKey !== legacyUserKeyString || searchKey.length < legacyUserKeyString.length) {
        contentChangedTimer = setTimeout(() => handleUserKeyStringChange(searchKey), 1000);
      }
    }
  };

  useEffect(() => {
    setUserGroupName(userGroup?.name);
    setUserIds(userGroup?.userMarks);
    setUserKeyString('');
  }, [userGroup]);

  const handleUserGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserGroupName(event.target.value);
  };

  const isCurrentUserGroupSettingChanged = () => {
    return userGroup?.name !== userGroupName || !isArraiesEqualByGUID(userGroup?.userMarks, userIds);
  };

  const handleUserGroupUserDeleted = (guid: string | undefined) => {
    setUserIds(userIds?.filter((id: any) => id.guid !== guid));
  };

  const handleUserGroupUserEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    triggerEnterKeyFunction(event, handleAddUser);
  };

  const handleAddUser = () => {
    if (userMark) {
      let newUser: UIShareTargetShape = {
        id: userMark?.userId,
        label: userMark?.userName
      };
      var filteredItems: any = userIds?.filter((ms: any) => ms.id !== userMark?.userId);
      setUserIds([...filteredItems, newUser]);
    }
  };

  const onSaveClick = () => {
    onUserGroupSave(Object.assign({}, userGroup, { name: userGroupName, userMarks: userIds }));
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'stretch',
        width: '100%',
        height: '100%',
        padding: '0.2em',
        backgroundColor: 'secondary.light'
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          alignItems: 'stretch'
        }}
      >
        <Box
          sx={{
            flexGrow: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <FormControl sx={{ width: '100%' }} size="small">
              <TextField
                id="editUserGroup"
                type="text"
                fullWidth
                value={userGroupName}
                inputProps={{ maxLength: maxLengthGroupName }}
                InputProps={{ sx: { borderRadius: '0.5em', backgroundColor: 'background.paper' } }}
                onChange={handleUserGroupChange}
                sx={{ backgroundColor: 'transparent' }}
              />
            </FormControl>
          </Box>
        </Box>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="user-content" id="user-header" sx={{ fontWeight: 'bolder' }}>
            <Person /> <Divider orientation="vertical" flexItem sx={{ ml: '0.3em', mr: '0.3em' }}></Divider> {t('shareTargeTypeUser')}
          </AccordionSummary>
          <AccordionDetails>
            <Autocomplete
              size="small"
              fullWidth
              options={suggestUsers}
              autoComplete
              autoHighlight
              freeSolo
              key={userGroup?.guid}
              onChange={(event, value) => setUserMark(value)}
              getOptionKey={(option: any) => option.userId || ''}
              getOptionLabel={(option: any) => option.userName || ''}
              renderOption={(props, option: any) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.userName} ({option.userId})
                </Box>
              )}
              filterOptions={(options, state) => {
                let newOptions: any = [];
                options.forEach((userOption: any) => {
                  if (userOption.userName.includes(state.inputValue.toLowerCase()) || userOption.userId.includes(state.inputValue.toLowerCase())) newOptions.push(userOption);
                });
                return newOptions;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('shareUserLabel')}
                  placeholder={t('shareUserPlaceHolder')}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: ''
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    changeOptionBaseOnValue(event.target.value);
                  }}
                  onKeyDown={handleUserGroupUserEnter}
                />
              )}
              onInputChange={(event: object, value: string, reason: string) => {
                if (reason === 'input') {
                  changeOptionBaseOnValue(value);
                }
              }}
            />
            <ChipArray limit={10} values={userIds} onValueDeleted={handleUserGroupUserDeleted}></ChipArray>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box
        sx={{
          flexGrow: 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'stretch',
          padding: '0.5em'
        }}
      >
        <Button variant="contained" onClick={onSaveClick} disabled={!isCurrentUserGroupSettingChanged()}>
          {t('buttonSave')}
        </Button>
      </Box>
    </Box>
  );
}
