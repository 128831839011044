import React, { useEffect, useRef } from 'react';

import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';

import * as echarts from 'echarts';
import 'echarts-wordcloud';

import EChartsReact from 'echarts-for-react';

let option: any = {
  // title: {
  //   text: 'Tags cloud',
  //   link: 'https://www.wisememo.com/s?wd=',
  //   x: 'center',
  //   textStyle: {
  //     fontSize: 23
  //   }
  // },
  // backgroundColor: '#F7F7F7',
  tooltip: {
    show: true
  },
  series: [
    {
      type: 'wordCloud',
      gridSize: 20,
      sizeRange: [12, 50],
      rotationRange: [0, 0],
      shape: 'circle',
      left: 'center',
      top: 'center',
      drawOutOfBound: false,
      layoutAnimation: true,
      textStyle: {
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        // Color can be a callback function or a color string
        color: function () {
          // Random color
          return 'rgb(' + [Math.round(Math.random() * 160), Math.round(Math.random() * 160), Math.round(Math.random() * 160)].join(',') + ')';
        }
      },
      emphasis: {
        focus: 'self',

        textStyle: {
          textShadowBlur: 10,
          textShadowColor: 'text.secondary'
        }
      },
      color: function () {
        let color = ['#27D38A', '#FFCA1C', '#5DD1FA', '#F88E25', '#47A0FF', '#FD6565'];
        let num = Math.floor(Math.random() * (5 + 1));
        return color[num];
      },
      data: []
    }
  ]
};

export function Tags(props: any) {
  const { t } = useTranslation();
  let echartRef: any = null;

  let { tags } = props;

  useEffect(() => {
    var JosnList: any = [];
    tags.map((t: any) => JosnList.push({ name: t.label, value: t.level }));
    option.series[0].data = JosnList;
    if (echartRef) {
      const instance = echartRef.getEchartsInstance();
      instance.setOption(option);
    }
  }, [tags]);

  return (
    <Box sx={{ width: '100%' }}>
      {tags && (
        <EChartsReact
          ref={(e) => {
            echartRef = e;
          }}
          option={option}
        ></EChartsReact>
      )}
    </Box>
  );
}
