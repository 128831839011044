import { DEFAULT_REQUEST_OPTIONS } from '../../../common/requestParameter';
import { SOURCE_ID, userServerAddress } from '../../../common/MetaSetting';
import { fetchHttpResponseHandler, fetchHttpExceptionHandler } from '../../../util/UtilFunction';

export function fetchUsersByKey(oidcFetch: Function, key: string) {
  return oidcFetch(`${userServerAddress}/check/user/${key}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchUserMarksByIds(oidcFetch: Function, ids: string[]) {
  var myInit = Object.assign({}, DEFAULT_REQUEST_OPTIONS, {
    method: 'POST',
    body: JSON.stringify(ids)
  });

  return oidcFetch(`${userServerAddress}/info/users`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchUser(oidcFetch: Function) {
  return oidcFetch(`${userServerAddress}/user/${SOURCE_ID}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchUsers(oidcFetch: Function) {
  return oidcFetch(`${userServerAddress}/user/all`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function updateUser(oidcFetch: Function, userInfo: any) {
  var myInit = Object.assign({}, DEFAULT_REQUEST_OPTIONS, {
    method: 'PUT',
    body: JSON.stringify(userInfo)
  });

  return oidcFetch(`${userServerAddress}/user`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function resetPassword(oidcFetch: Function, userId: string, password: string) {
  let updatePassword = { userName: userId, password: password };
  var myInit = Object.assign({}, DEFAULT_REQUEST_OPTIONS, {
    method: 'PUT',
    body: JSON.stringify(updatePassword)
  });

  return oidcFetch(`${userServerAddress}/security/${userId}`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}
