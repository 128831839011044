import { UISectionShape, UIGroupShape, UIMemoShape, UIMemoContentShape, UIShareShape, UIUserGroupShape, UIPhraseShape } from '../model/MemoModelShapes';
import { DEFAULT_SECTION_GUID, DEFAULT_GROUP_GUID } from './MetaSetting';

export const defaultSection: UISectionShape = {
  userId: '',
  guid: DEFAULT_SECTION_GUID,
  createTimestamp: 0,
  stateCode: 1,
  modifyTimestamp: 0,
  name: '',
  sectionType: 0,
  hasShare: false
};

export const defaultGroup: UIGroupShape = {
  userId: '',
  guid: DEFAULT_GROUP_GUID,
  createTimestamp: 0,
  stateCode: 1,
  description: '',
  modifyTimestamp: 0,
  name: '',
  sectionId: '-',
  hasShare: false
};

export const emptySection: UISectionShape = {
  userId: '',
  guid: '',
  createTimestamp: 0,
  stateCode: 1,
  modifyTimestamp: 0,
  name: '',
  sectionType: 0,
  hasShare: false
};

export const getEmptySection = () => {
  return Object.assign({}, emptySection);
};

export const emptyGroup: UIGroupShape = {
  userId: '',
  guid: '',
  createTimestamp: 0,
  stateCode: 1,
  description: '',
  modifyTimestamp: 0,
  name: '',
  sectionId: '',
  hasShare: false
};

export const getEmptyGroup = () => {
  return Object.assign({}, emptyGroup);
};

export const MemoStatus = {
  UNKNOWN: 0,
  UPDATED: 1,
  UPDATING: 2,
  NOTUPDATE: 3
};

export const emptyMemo: UIMemoShape = {
  userId: '',
  guid: '',
  createTimestamp: 0,
  stateCode: 1,
  modifyTimestamp: 0,
  title: '',
  groupId: '',
  syncStateCode: 0,
  tags: undefined,
  hasShare: false,
  updateStatus: MemoStatus.UPDATED
};

export const getEmptyMemo = () => {
  return Object.assign({}, emptyMemo);
};

export const emptyContentMemo: UIMemoContentShape = {
  userId: '',
  guid: '',
  createTimestamp: 0,
  stateCode: 1,
  content: '',
  modifyTimestamp: 0
};

export const getEmptyMemoContent = () => {
  return Object.assign({}, emptyContentMemo);
};

export const emptyShareSetting: UIShareShape = {
  typeMark: 0,
  title: '',
  guid: '',
  createTimestamp: 0,
  modifyTimestamp: 0,
  isPublic: false,
  targetUserGroupIds: [],
  targetUserIds: []
};

export const getEmptyShare = () => {
  return Object.assign({}, emptyShareSetting);
};

export const SHARE_SETTING_TYPE_MARK = {
  PUBLIC: 1,
  USER_GROUP: 2,
  USER: 3
};

export const SHARE_TYPE_MARK = {
  SECTION: 1,
  GROUP: 2,
  MEMO: 3
};

export const MEMO_PAGE_LIST_PANEL = {
  SECTION: 1,
  GROUP: 2,
  MEMO: 3,
  SHARE: 4
};

export const MEMO_PAGE_VIEW_PANEL = {
  EDIT: 1,
  SHARE: 2
};

export const defaultUserGroup: UIUserGroupShape = {
  userId: '',
  guid: DEFAULT_SECTION_GUID,
  createTimestamp: 0,
  stateCode: 1,
  modifyTimestamp: 0,
  name: '',
  userMarks: []
};

export const emptyUserGroup: UIUserGroupShape = {
  userId: '',
  guid: '',
  createTimestamp: 0,
  stateCode: 1,
  modifyTimestamp: 0,
  name: '',
  userMarks: []
};

export const getEmptyUserGroup = () => {
  return Object.assign({}, emptyUserGroup);
};

export const VIEW_MEMO_STATUS_MARK = {
  SUCCESS: 0,
  TARGET_NOT_FOUND: 1,
  NOT_AUTH: 2
};

export const SyncResult = {
  SOURCE_UPDATED: -1,
  TARGET_UPDATED: 1,
  NO_UPDATE: 0,
  OTHER: 2
};

export const UpdateTarget = {
  SUCCESS: 0,
  SECTION: 1,
  GROUP: 2,
  MEMO: 3,
  MEMO_TAG: 4,
  MEMO_TRAINING: 5
};

export const UpdateType = {
  UNKNOWN: 0,
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3
};

export const SocketMessageType = {
  UNKNOWN: 0,
  MEMO_UPDATE: 1,
  COMMAND_RESULT: 2,
  TRAINING_RESULT: 3
};

export const PhraseContentType = {
  SELF: 0,
  SYSTEM: 1,
  OTHER: 2
};

export const emptyPhrase: UIPhraseShape = {
  userId: '',
  guid: '',
  createTimestamp: 0,
  stateCode: 1,
  content: '',
  contentType: 0,
  modifyTimestamp: 0,
  title: '',
  tags: '',
  isNew: true
};

export const getEmptyPhrase = () => {
  return Object.assign({}, emptyPhrase);
};
