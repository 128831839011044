import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';

import { LOAD_USER, LOAD_USERS, UPDATE_USER, RESET_PASSWORD, LOAD_USER_MARKS } from '../../constants/ReduxConst';
import { fetchUser, fetchUserMarksByIds, fetchUsers, resetPassword, updateUser } from './userAPI';
import { retryInterval } from '../../../common/MetaSetting';
import { FetchResponseShape } from '../../../model/MemoModelShapes';
import { HttpResponseType, MessageType } from '../../../model/AppUIShapes';
import { appendMessageAsync } from '../../../appSlice';

export interface LocalUserState {
  status: 'idle' | 'loading' | 'failed';
  currentUser: any | undefined;
  users: any[];
  userMarks: any[];
}

const initialState: LocalUserState = {
  status: 'idle',
  currentUser: undefined,
  users: [],
  userMarks: []
};

const fetchUserRetry = (oidcFetch: Function, thunkAPI: any) => {
  fetchUser(oidcFetch).then((userGroupResponse: FetchResponseShape) => {
    if (userGroupResponse.responseType === HttpResponseType.SUCCESS) {
      userGroupResponse.content.then((currentUser: any) => {
        thunkAPI.dispatch(saveUserToLocal(currentUser));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: userGroupResponse.message }));
      setTimeout(() => fetchUserRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

export const fetchUserAsync = createAsyncThunk<number, Function, { state: RootState }>(LOAD_USER, async (oidcFetch, thunkAPI) => {
  fetchUserRetry(oidcFetch, thunkAPI);

  return new Date().getTime();
});

const fetchUsersRetry = (oidcFetch: Function, thunkAPI: any) => {
  fetchUsers(oidcFetch).then((userGroupResponse: FetchResponseShape) => {
    if (userGroupResponse.responseType === HttpResponseType.SUCCESS) {
      userGroupResponse.content.then((users: any) => {
        thunkAPI.dispatch(saveUsersToLocal(users));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: userGroupResponse.message }));
      setTimeout(() => fetchUsersRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

export const fetchUsersAsync = createAsyncThunk<number, Function, { state: RootState }>(LOAD_USERS, async (oidcFetch, thunkAPI) => {
  fetchUsersRetry(oidcFetch, thunkAPI);

  return new Date().getTime();
});

const fetchUserMarksByIdsRetry = (oidcFetch: Function, userIds: any[], thunkAPI: any) => {
  fetchUserMarksByIds(oidcFetch, userIds).then((userGroupResponse: FetchResponseShape) => {
    if (userGroupResponse.responseType === HttpResponseType.SUCCESS) {
      userGroupResponse.content.then((userMarks: any) => {
        thunkAPI.dispatch(saveUserMarksToLocal(userMarks));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: userGroupResponse.message }));
      setTimeout(() => fetchUserMarksByIdsRetry(oidcFetch, userIds, thunkAPI), retryInterval);
    }
  });
};

export const fetchUserMarksAsync = createAsyncThunk<number, any, { state: RootState }>(LOAD_USER_MARKS, async (param, thunkAPI) => {
  if (param.userIds && param.userIds.length > 0) {
    var filteredItems = param.userIds.filter((i: any) => {
      var foundOne = thunkAPI.getState().user.userMarks.filter((um: any) => um.userId === i);
      return !(foundOne && foundOne.length > 0);
    });

    if (filteredItems && filteredItems.length > 0) {
      fetchUserMarksByIdsRetry(param.oidcFetch, param.userIds, thunkAPI);
    }
  }

  return new Date().getTime();
});

const updateUserRetry = (oidcFetch: Function, thunkAPI: any) => {
  updateUser(oidcFetch, thunkAPI.getState().user.currentUser).then((userGroupResponse: FetchResponseShape) => {
    if (userGroupResponse.responseType === HttpResponseType.SUCCESS) {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.SUCCESS, message: 'messageIdUpdateSuccess' }));
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: userGroupResponse.message }));
      setTimeout(() => updateUserRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

export const updateUserAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_USER, async (param, thunkAPI) => {
  thunkAPI.dispatch(saveUserToLocal(param.userInfo));
  updateUserRetry(param.oidcFetch, thunkAPI);

  return new Date().getTime();
});

export const resetPasswordAsync = createAsyncThunk<number, any, { state: RootState }>(RESET_PASSWORD, async (param, thunkAPI) => {
  const state = thunkAPI.getState();

  if (state.user.currentUser) {
    resetPassword(param.oidcFetch, state.user.currentUser.userId, param.password).then((userGroupResponse: FetchResponseShape) => {
      if (userGroupResponse.responseType === HttpResponseType.SUCCESS) {
        thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.SUCCESS, message: 'messageIdUpdateSuccess' }));
      } else {
        thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: userGroupResponse.message }));
      }
    });
  }
  return new Date().getTime();
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUserToLocal: (state, action: PayloadAction<any>) => {
      state.currentUser = Object.assign({}, action.payload);
    },
    saveUsersToLocal: (state, action: PayloadAction<any>) => {
      state.users = [...action.payload];
    },
    saveUserMarksToLocal: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        var filteredItems = state.userMarks.filter((um) => {
          var foundOne = action.payload.filter((fum: any) => {
            return fum.userId === um.userId;
          });
          return !(foundOne && foundOne.length > 0);
        });
        state.userMarks = [...filteredItems, ...action.payload];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(fetchUserAsync.rejected, (state) => {
        console.log('fetchUserAsync.rejected');
        state.status = 'failed';
      })
      .addCase(fetchUsersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(fetchUsersAsync.rejected, (state) => {
        console.log('fetchUsersAsync.rejected');
        state.status = 'failed';
      })
      .addCase(fetchUserMarksAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserMarksAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(fetchUserMarksAsync.rejected, (state) => {
        console.log('fetchUserMarksAsync.rejected');
        state.status = 'failed';
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(updateUserAsync.rejected, (state) => {
        console.log('updateUserAsync.rejected');
        state.status = 'failed';
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetPasswordAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(resetPasswordAsync.rejected, (state) => {
        console.log('resetPasswordAsync.rejected');
        state.status = 'failed';
      });
  }
});

export const { saveUserToLocal, saveUsersToLocal, saveUserMarksToLocal } = userSlice.actions;

export const selectCurrentUser = (state: RootState) => state.user.currentUser;
export const selectUsers = (state: RootState) => state.user.users;
export const selectUserMarks = (state: RootState) => state.user.userMarks;

export default userSlice.reducer;
