import React, { useState, useEffect, useRef } from 'react';

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Button,
  Avatar,
  ListItemAvatar,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Divider,
  Typography,
  FormControl,
  ListItemSecondaryAction
} from '@mui/material';

import { MoreHoriz, PieChart, Star, Add, Delete, Edit, Share } from '@mui/icons-material';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { selectSections, selectCurrentSection, changeSelectedSectionId, selectSectionShares, updateSectionAsync, selectedSectionId } from './sectionSlice';

import { transferToSectionBySectionIdAsync } from '../group/groupSlice';

import { UISectionShape } from '../../../model/MemoModelShapes';
import { MessageType } from '../../../model/AppUIShapes';
import { Alert, ConfirmationDialog } from '../../shared';
import getNewGUIDString from '../../../util/GUID';
import { findOneWMObjectFromAll, triggerEnterKeyFunction } from '../../../common/Utility';
import { MEMO_PAGE_LIST_PANEL, MEMO_PAGE_VIEW_PANEL, SHARE_TYPE_MARK, emptySection, getEmptySection, getEmptyShare } from '../../../common/MetaData';
import { DEFAULT_SECTION_GUID, leftListWidth, maxLengthSectionName } from '../../../common/MetaSetting';
import { useTranslation } from 'react-i18next';
import { appendMessageAsync, changeCurrentListPanel, changeCurrentViewPanel } from '../../../appSlice';
import { changeCurrentShare } from '../../shareSlice';
import { useOidcFetch } from '@axa-fr/react-oidc';

export function SectionList(props: any) {
  const currentSelectedId = useAppSelector(selectedSectionId);
  const sections = useAppSelector(selectSections);
  const sectionShares = useAppSelector(selectSectionShares);
  const dispatch = useAppDispatch();
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { fetch } = useOidcFetch();

  const [editingSection, setEditingSection] = useState<UISectionShape>(emptySection);
  const currentSection: any = useAppSelector(selectCurrentSection);
  const [openEdit, setOpenEdit] = useState(false);

  const [editName, setEditName] = useState<string | undefined>('');

  const [openCreate, setOpenCreate] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMoreMenu = Boolean(anchorEl);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [moreId, setMoreId] = useState<string | undefined>(currentSelectedId);

  const setCurrentEditingSection = (section: UISectionShape) => {
    setEditingSection(section);
    setEditName(section.name);
  };

  const handleCreateSection = () => {
    let newSection: UISectionShape = Object.assign(getEmptySection(), { name: t('defaultSectionName') });
    setCurrentEditingSection(newSection);
    setOpenCreate(true);
    if (addButtonRef && addButtonRef.current) addButtonRef.current.blur();
  };

  const handleMoreOperationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    let tmpMoreId: string | null = event.currentTarget.getAttribute('data-item-guid');
    setMoreId(tmpMoreId ? tmpMoreId : '');

    let tmpEditName: string | null = event.currentTarget.getAttribute('data-item-section-name');
    setEditName(tmpEditName ? tmpEditName : '');
  };

  const handleSectionClick = (sectionId: string) => () => {
    setMoreId(sectionId);
    dispatch(changeCurrentViewPanel(MEMO_PAGE_VIEW_PANEL.EDIT));
    // if (currentSection && sectionId === currentSection.guid) return;

    dispatch(changeCurrentListPanel(MEMO_PAGE_LIST_PANEL.GROUP));
    if (sectionId === DEFAULT_SECTION_GUID) {
      dispatch(changeSelectedSectionId(DEFAULT_SECTION_GUID));
      return;
    }

    let section = findOneWMObjectFromAll(sections, sectionId);
    if (section) dispatch(changeSelectedSectionId(section.guid));
  };

  const handleShareClick = (sectionId: any) => {
    setMoreId(sectionId);
    let selectedSection = findOneWMObjectFromAll(sections, sectionId);
    if (sectionId !== currentSection?.guid) changeSelectedSectionId(sectionId);

    setAnchorEl(null);
    let selectedSectionShare = findOneWMObjectFromAll(sectionShares, sectionId);
    let currentShare = undefined;
    if (selectedSectionShare) {
      currentShare = Object.assign({}, selectedSectionShare, {
        typeMark: SHARE_TYPE_MARK.SECTION,
        title: selectedSection?.name
      });
    } else {
      currentShare = Object.assign(getEmptyShare(), {
        typeMark: SHARE_TYPE_MARK.SECTION,
        title: selectedSection?.name,
        guid: sectionId
      });
    }
    // setOpenShare(true);

    dispatch(changeCurrentShare(currentShare));
    dispatch(changeCurrentViewPanel(MEMO_PAGE_VIEW_PANEL.SHARE));
  };

  const handleMoreMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    let section = findOneWMObjectFromAll(sections, moreId);
    if (section) {
      setCurrentEditingSection(Object.assign({}, section));
      setOpenEdit(true);
    }
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setOpenConfirm(true);
  };

  const isCurrentSectionChanged = () => {
    return !(editingSection.name === editName);
  };

  const cleanEditingSection = () => {
    setEditingSection(emptySection);

    setOpenEdit(false);
    setEditName('');
  };

  const handleOnClose = () => {
    if (isCurrentSectionChanged()) {
      dispatch(appendMessageAsync({ messageType: MessageType.WARNING, message: t('warningMessageChangeNotSave') }));
      return;
    }

    setOpenCreate(false);
    setOpenEdit(false);
    cleanEditingSection();
  };

  const handleEditSectionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditName(event.target.value);
  };

  const handleCreateKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    triggerEnterKeyFunction(event, handleCreate);
  };

  const handleEditKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    triggerEnterKeyFunction(event, handleEditSave);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
    cleanEditingSection();
  };

  const handleCreate = () => {
    let newSection: UISectionShape = Object.assign(getEmptySection(), {
      guid: getNewGUIDString(),
      name: editName
    });
    dispatch(updateSectionAsync({ oidcFetch: fetch, section: newSection }));
    handleCreateClose();
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    cleanEditingSection();
  };

  const handleEditSave = () => {
    dispatch(
      updateSectionAsync({
        oidcFetch: fetch,
        section: Object.assign(editingSection, { name: editName })
      })
    );
    setOpenEdit(false);
    cleanEditingSection();
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setMoreId('');
    setEditName('');
  };

  const handleConfirmDelete = (selectedId: string | undefined) => {
    if (selectedId) {
      let currentSection = findOneWMObjectFromAll(sections, selectedId);
      if (currentSection)
        dispatch(
          updateSectionAsync({
            oidcFetch: fetch,
            section: Object.assign({}, currentSection, { stateCode: 0 })
          })
        );
      setOpenConfirm(false);
      setMoreId('');
      dispatch(
        transferToSectionBySectionIdAsync({
          oidcFetch: fetch,
          sectionId: selectedId
        })
      );
    } else {
      setOpenConfirm(false);
      setMoreId('');
    }
  };

  const handleShareIconClick = (selectedId: string | undefined) => {
    setAnchorEl(null);
    let targetMemo = findOneWMObjectFromAll(sections, selectedId);
    if (targetMemo) {
      // setCurrentShareMemo(Object.assign({}, targetMemo.shareSetting));
      // setOpenShare(true);
    }
  };

  let listCount: number = 0;
  if (sections) listCount = sections.filter((item) => item.stateCode !== 0).length;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'stretch',
        width: '100%',
        height: '100%'
      }}>
      <Box>
        <Box
          sx={{
            minWidth: 300,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              flexGrow: 1,
              fontWeight: 'bolder'
            }}
            component="span">
            {t('titleSections')} {'(' + listCount + ')'}
          </Typography>
          <IconButton color="primary" onClick={handleCreateSection} ref={addButtonRef}>
            <Add />
          </IconButton>
        </Box>
        <Box className="children-content">
          <List sx={{ width: leftListWidth, bgcolor: 'background.paper' }} dense>
            <ListItem key="default-guid" disablePadding>
              <ListItemButton
                sx={{
                  padding: 0,
                  '&.Mui-selected': {
                    backgroundColor: 'primary.light',
                    color: 'primary.main',
                    fontWeight: 'bolder'
                  },
                  '&:hover': {
                    backgroundColor: 'primary.light',
                    color: 'primary.main'
                  }
                }}
                role={undefined}
                onClick={handleSectionClick(DEFAULT_SECTION_GUID)}>
                <ListItemAvatar
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    minWidth: 36
                  }}>
                  <Star color="primary" fontSize="small" />
                </ListItemAvatar>
                <ListItemText
                  id="default-section"
                  sx={{ color: 'inherit', background: 'inherit', fontWeight: 'bolder' }}
                  primary={t('titleDefaultSection')}
                  secondary={<React.Fragment>{t('descriptionDefaultSection')}</React.Fragment>}
                />
              </ListItemButton>
            </ListItem>
            {sections &&
              sections
                .filter((s) => s.stateCode !== 0)
                .map((uiSection: UISectionShape) => {
                  const labelId = `checkbox-list-label-${uiSection.guid}`;
                  return (
                    <ListItem
                      key={uiSection.guid}
                      sx={{
                        '&:hover .moreOperation': { visibility: 'inherit' }
                      }}
                      secondaryAction={
                        <IconButton
                          className="moreOperation"
                          sx={{ visibility: 'hidden' }}
                          data-item-guid={uiSection.guid}
                          data-item-section-name={uiSection.name}
                          edge="end"
                          onClick={handleMoreOperationClick}>
                          <MoreHoriz />
                        </IconButton>
                      }
                      disablePadding>
                      <ListItemButton
                        sx={{
                          padding: 0,
                          '&.Mui-selected': {
                            backgroundColor: 'primary.light',
                            color: 'primary.main',
                            fontWeight: 'bolder'
                          },
                          '&:hover': {
                            backgroundColor: 'primary.light',
                            color: 'primary.main'
                          }
                        }}
                        role={undefined}
                        selected={uiSection.guid === moreId}>
                        {/* <ListItemAvatar>
                          <Avatar>
                            <PieChart />
                          </Avatar>
                        </ListItemAvatar> */}
                        <ListItemAvatar
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                            minWidth: 36,
                            '&:hover': { bgcolor: 'rgb(0 0 0 / 4%)' }
                          }}
                          // onClick={() => handleShareIconClick(uiSection.guid)}
                          onClick={() => handleShareClick(uiSection.guid)}>
                          <Share
                            sx={{
                              color: uiSection.hasShare ? 'primary.main' : 'secondary.main'
                            }}
                            fontSize="small"
                          />
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <IconButton
                            className="moreOperation"
                            sx={{ visibility: 'hidden' }}
                            data-item-guid={uiSection.guid}
                            data-item-section-name={uiSection.name}
                            edge="end"
                            onClick={handleMoreOperationClick}>
                            <MoreHoriz />
                          </IconButton>
                        </ListItemSecondaryAction>
                        <ListItemText
                          id={labelId}
                          primary={
                            <Typography sx={{ color: 'inherit', background: 'inherit', fontWeight: 'inherit' }} noWrap>
                              {uiSection.name}
                            </Typography>
                          }
                          onClick={handleSectionClick(uiSection.guid)}
                          secondary={<React.Fragment>{new Date(uiSection.modifyTimestamp).toLocaleDateString()}</React.Fragment>}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
          </List>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMoreMenu}
        onClose={handleMoreMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem dense onClick={handleEdit}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>{t('buttonEdit')}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem dense onClick={handleDelete}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>{t('buttonDelete')}</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog open={openCreate} onClose={handleOnClose} fullWidth maxWidth="sm">
        <DialogTitle>{t('titleCreateSection')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{/* Create a new section. */}</DialogContentText>
          <FormControl sx={{ p: 1, width: '100%' }} size="small">
            <TextField
              autoFocus
              id="createSectionName"
              label={t('labelName')}
              type="text"
              fullWidth
              variant="standard"
              value={editName}
              inputProps={{ maxLength: maxLengthSectionName }}
              onChange={handleEditSectionNameChange}
              onKeyDown={handleCreateKeyPress}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateClose} variant="outlined" color="error">
            {t('buttonCancel')}
          </Button>
          <Button onClick={handleCreate} variant="contained" color="success">
            {t('buttonCreate')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEdit} onClose={handleOnClose} fullWidth maxWidth="sm">
        <DialogTitle>{t('titleEditSection')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{/* Update section. */}</DialogContentText>
          <FormControl sx={{ p: 1, width: '100%' }} size="small">
            <TextField
              autoFocus
              id="editName"
              label={t('labelName')}
              type="text"
              fullWidth
              variant="standard"
              value={editName}
              inputProps={{ maxLength: maxLengthSectionName }}
              onChange={handleEditSectionNameChange}
              onKeyDown={handleEditKeyPress}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} variant="outlined" color="error">
            {t('buttonCancel')}
          </Button>
          <Button onClick={handleEditSave} variant="contained" color="primary">
            {t('buttonSave')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        id="confirmDelete"
        keepMounted
        open={openConfirm}
        onClose={handleConfirmClose}
        onConfirm={handleConfirmDelete}
        keyValue={moreId}
        titleValue={t('titleDeleteSection')}
        messageValue={t('warningMessageDeleteConfirm') + editName}
      />
    </Box>
  );
}
