import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useOidc } from '@axa-fr/react-oidc';

import { Box, Button, Typography } from '@mui/material';

export const SessionLostComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const countSeconds = 30;
  const [countDown, setCountDown] = useState(countSeconds);

  const countDownDate = new Date();
  countDownDate.setSeconds(countDownDate.getSeconds() + countSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      const nowTime = new Date();
      let leftCount = Math.ceil((countDownDate.getTime() - nowTime.getTime()) / 1000);
      setCountDown(leftCount);
      if (leftCount < 1) handleRefresh();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography>{t('sessionLostPrompt', { countDown: countDown })}</Typography>
        <Button onClick={handleRefresh} variant="contained" sx={{ backgroundColor: 'primary.main' }}>
          {t('buttonRefresh')}
        </Button>
      </Box>
    </Box>
  );
};

export default SessionLostComponent;
