import React, { useState, useEffect, useRef } from 'react';

import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, Box, Menu, MenuItem, Typography, ListItemAvatar, ListItemSecondaryAction, Divider } from '@mui/material';

import { MoreHoriz, Add, Delete, Share, ImportContacts } from '@mui/icons-material';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import {
  syncMemoByGroupAsync,
  selectMemos,
  changeSelectedMemoId,
  selectCurrentMemo,
  selectMemoShares,
  syncMemoShareByGroupAsync,
  updateMemoAsync,
  createMemoAsync,
  refreshMemoRunTrainingAsync
} from './memoSlice';

import { UIMemoContentShape, UIMemoShape } from '../../../model/MemoModelShapes';
import { ConfirmationDialog } from '../../shared';
import getNewGUIDString from '../../../util/GUID';
import { findOneWMObjectFromAll, findOneTargetWithGUID } from '../../../common/Utility';
import { getEmptyMemo, getEmptyMemoContent, getEmptyShare, MEMO_PAGE_VIEW_PANEL, SHARE_TYPE_MARK } from '../../../common/MetaData';
import { useTranslation } from 'react-i18next';
import { selectCurrentGroup } from '../group/groupSlice';
import { changeCurrentShare } from '../../shareSlice';
import { changeCurrentViewPanel } from '../../../appSlice';
import { useOidcFetch } from '@axa-fr/react-oidc';
import { leftListWidth } from '../../../common/MetaSetting';

export function MemoList(props: any) {
  const memos = useAppSelector(selectMemos);
  const memoShares = useAppSelector(selectMemoShares);
  const dispatch = useAppDispatch();
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const currentMemo: UIMemoShape | undefined = useAppSelector(selectCurrentMemo);
  const [memoObject, setMemoObject] = useState<UIMemoShape | undefined>(currentMemo);
  const currentGroup: any = useAppSelector(selectCurrentGroup);

  const [editTitle, setEditTitle] = useState<string | null>('');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMoreMenu = Boolean(anchorEl);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [moreId, setMoreId] = useState<string | undefined>('');

  const { fetch } = useOidcFetch();

  useEffect(() => {
    setMemoObject(currentMemo);
    setMoreId(currentMemo?.guid);
  }, [currentMemo]);

  useEffect(() => {
    dispatch(syncMemoByGroupAsync({ oidcFetch: fetch, group: currentGroup }));
    dispatch(syncMemoShareByGroupAsync({ oidcFetch: fetch, group: currentGroup }));
  }, []);

  const changeCurrentMemo = (memo: UIMemoShape | undefined) => {
    setEditTitle(memo ? memo.title : '');
    dispatch(changeSelectedMemoId(memo ? memo.guid : ''));
  };

  const handleCreateMemo = () => {
    let newMemo: UIMemoShape = Object.assign(getEmptyMemo(), {
      guid: getNewGUIDString(),
      title: t('defaultMemoTitle'),
      groupId: currentGroup.guid
    });
    dispatch(createMemoAsync({ oidcFetch: fetch, memo: newMemo }));
    changeCurrentMemo(newMemo);
    if (addButtonRef && addButtonRef.current) addButtonRef.current.blur();
    dispatch(changeCurrentViewPanel(MEMO_PAGE_VIEW_PANEL.EDIT));
  };

  const handleMoreOperationClick = (memoId: string, event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMoreId(memoId);
    dispatch(changeCurrentViewPanel(MEMO_PAGE_VIEW_PANEL.EDIT));

    let targetMemo = findOneWMObjectFromAll(memos, memoId);
    if (targetMemo) changeCurrentMemo(targetMemo);
    setEditTitle(targetMemo ? targetMemo.title : '');
  };

  const handleMemoClick = (memoId: string) => () => {
    setMoreId(memoId);
    dispatch(changeCurrentViewPanel(MEMO_PAGE_VIEW_PANEL.EDIT));

    let targetMemo = findOneWMObjectFromAll(memos, memoId);
    if (targetMemo) changeCurrentMemo(targetMemo);
    setEditTitle(targetMemo ? targetMemo.title : '');
  };

  const handleMoreMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTraining = () => {
    setAnchorEl(null);
    if (moreId) {
      let memoObject = findOneWMObjectFromAll(memos, moreId);
      if (memoObject)
        dispatch(
          refreshMemoRunTrainingAsync({
            oidcFetch: fetch,
            guid: memoObject.guid
          })
        );
    }
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setOpenConfirm(true);
  };

  // const handleShare = () => {
  //   handleShareClick(moreId);
  // };

  const handleShareClick = (memoId: any) => {
    setMoreId(memoId);
    let targetMemo = findOneWMObjectFromAll(memos, memoId);
    if (memoId !== targetMemo?.guid) {
      if (targetMemo) changeCurrentMemo(targetMemo);
    }

    setAnchorEl(null);
    let selectedMemoShare = findOneTargetWithGUID(memoShares, memoId);
    let currentShare = undefined;
    if (selectedMemoShare) {
      currentShare = Object.assign({}, selectedMemoShare, {
        typeMark: SHARE_TYPE_MARK.MEMO,
        title: targetMemo?.title
      });
    } else {
      currentShare = Object.assign(getEmptyShare(), {
        typeMark: SHARE_TYPE_MARK.MEMO,
        title: targetMemo?.title,
        guid: memoId
      });
    }
    // setOpenShare(true);
    dispatch(changeCurrentShare(currentShare));
    dispatch(changeCurrentViewPanel(MEMO_PAGE_VIEW_PANEL.SHARE));
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setMoreId('');
    setEditTitle('');
  };

  // const handleShareIconClick = (selectedId: string) => {
  //   setAnchorEl(null);
  //   let selectedMemoShareSetting = findOneTargetWithGUID(memoShares, selectedId);
  //   if (selectedMemoShareSetting) {
  //     setCurrentMemoShareSetting(Object.assign({}, selectedMemoShareSetting));
  //   } else {
  //     setCurrentMemoShareSetting(Object.assign(getEmptyShare(), { guid: selectedId }));
  //   }
  //   setOpenShare(true);
  // };

  const handleConfirmDelete = (selectedId: string | undefined) => {
    if (selectedId) {
      let memoObject = findOneWMObjectFromAll(memos, selectedId);
      if (memoObject)
        dispatch(
          updateMemoAsync({
            oidcFetch: fetch,
            memo: Object.assign({}, memoObject, { stateCode: 0 })
          })
        );
      setOpenConfirm(false);
      setMoreId('');
      let leftMemos = memos.filter((item) => item.stateCode !== 0 && item.groupId === currentGroup.guid && item.guid !== selectedId);
      if (leftMemos && leftMemos.length > 0) {
        changeCurrentMemo(leftMemos[0]);
      } else {
        changeCurrentMemo(undefined);
      }
    } else {
      setOpenConfirm(false);
      setMoreId('');
    }
  };

  let listCount: number = 0;
  if (memos) listCount = memos.filter((item) => item.stateCode !== 0 && item.groupId === currentGroup.guid).length;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'stretch',
        width: '100%',
        height: '100%'
      }}
    >
      <Box>
        <Box
          sx={{
            minWidth: 300,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              flexGrow: 1,
              fontWeight: 'bolder'
            }}
            component="span"
          >
            {t('titleMemos')} {'(' + listCount + ')'}
          </Typography>
          <IconButton color="primary" onClick={handleCreateMemo} ref={addButtonRef}>
            <Add />
          </IconButton>
        </Box>
        <Box className="children-content">
          <List sx={{ width: leftListWidth, bgcolor: 'background.paper' }} dense>
            {currentGroup &&
              memos &&
              memos
                .filter((m: UIMemoShape) => m.stateCode !== 0 && m.groupId === currentGroup.guid)
                .map((uiMemo: UIMemoShape) => {
                  const labelId = `checkbox-list-label-${uiMemo.guid}`;
                  return (
                    <ListItem
                      key={uiMemo.guid}
                      sx={{
                        '&:hover .moreOperation': { visibility: 'inherit' }
                      }}
                      disablePadding
                    >
                      <ListItemButton
                        sx={{
                          padding: 0,
                          '&.Mui-selected': {
                            backgroundColor: 'primary.light',
                            color: 'primary.main',
                            fontWeight: 'bolder'
                          },
                          '&:hover': {
                            backgroundColor: 'primary.light',
                            color: 'primary.main'
                          }
                        }}
                        role={undefined}
                        selected={uiMemo.guid === moreId}
                      >
                        {/* <ListItemIcon sx={{ margin: 0 }}>
                          <PublicIcon color="success" fontSize="small" />
                        </ListItemIcon>         */}
                        <ListItemAvatar
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                            minWidth: 36,
                            '&:hover': { bgcolor: 'rgb(0 0 0 / 4%)' }
                          }}
                          // onClick={() => handleShareIconClick(uiMemo.guid)}
                          onClick={() => handleShareClick(uiMemo.guid)}
                        >
                          <Share
                            sx={{
                              color: uiMemo.hasShare ? 'primary.main' : 'secondary.main'
                            }}
                            fontSize="small"
                          />
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <IconButton
                            className="moreOperation"
                            sx={{ visibility: 'hidden' }}
                            data-item-guid={uiMemo.guid}
                            data-item-memo-title={uiMemo.title}
                            edge="end"
                            onClick={(event) => handleMoreOperationClick(uiMemo.guid, event)}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </ListItemSecondaryAction>
                        <ListItemText
                          id={labelId}
                          primary={
                            <Typography sx={{ color: 'inherit', background: 'inherit', fontWeight: 'inherit' }} noWrap>
                              {uiMemo.title}
                            </Typography>
                          }
                          onClick={handleMemoClick(uiMemo.guid)}
                          secondary={
                            <React.Fragment>
                              {/* <Chip
                                color="success"
                                label="P"
                                onClick={handleShareStateClick}
                                icon={<PublicIcon />}
                                size="small"
                              /> */}
                              {new Date(uiMemo.modifyTimestamp).toLocaleDateString()}
                              {/* <Typography component="div" sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography component="div">{new Date(uiMemo.modifyTimestamp).toLocaleDateString()}</Typography>
                                <PublicIcon color="success" fontSize="small" />
                              </Typography> */}
                            </React.Fragment>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
          </List>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMoreMenu}
        onClose={handleMoreMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {/* <MenuItem dense onClick={handleTraining}>
          <ListItemIcon>
            <ImportContacts />
          </ListItemIcon>
          <ListItemText>{t('buttonTraining')}</ListItemText>
        </MenuItem>
        <Divider /> */}
        <MenuItem dense onClick={handleDelete}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>{t('buttonDelete')}</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        id="confirmDelete"
        keepMounted
        open={openConfirm}
        onClose={handleConfirmClose}
        onConfirm={handleConfirmDelete}
        keyValue={moreId}
        titleValue={t('titleDeleteMemo')}
        messageValue={t('warningMessageDeleteConfirm') + editTitle}
      />
    </Box>
  );
}
