import { DEFAULT_REQUEST_OPTIONS } from '../../../common/requestParameter';
import { wmServerAddress } from '../../../common/MetaSetting';
import { UIMemoShape, MemoSyncRequestShape, ShareSyncRequestShape, UIShareShape, UIShareStatusShape, UIShareInfoShape } from '../../../model/MemoModelShapes';
import { SHARE_SETTING_TYPE_MARK } from '../../../common/MetaData';
import { fetchHttpResponseHandler, fetchHttpExceptionHandler } from '../../../util/UtilFunction';
import { CLIENT_ID } from '../../../util/GUID';

export function fetchMemoShares(oidcFetch: Function, lastSyncTimestamp = 0) {
  return oidcFetch(`${wmServerAddress}/share/memos/${lastSyncTimestamp}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchMemos(oidcFetch: Function, lastSyncTimestamp = 0) {
  return oidcFetch(`${wmServerAddress}/memo/all/${lastSyncTimestamp}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchMemo(oidcFetch: Function, memoId: string) {
  return oidcFetch(`${wmServerAddress}/memo/${memoId}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchMemoContent(oidcFetch: Function, memoId: string) {
  return oidcFetch(`${wmServerAddress}/memo/content/${memoId}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchMemoShare(oidcFetch: Function, memoId: string) {
  return oidcFetch(`${wmServerAddress}/share/memo/${memoId}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function remoteMemoShareSync(oidcFetch: Function, memo: UIShareShape) {
  let shareInfo: UIShareInfoShape[] = [];
  if (memo.isPublic)
    shareInfo.push({
      targetType: SHARE_SETTING_TYPE_MARK.PUBLIC,
      targetId: ''
    });
  memo.targetUserGroupIds.map((userGroup) => {
    shareInfo.push({
      targetType: SHARE_SETTING_TYPE_MARK.USER_GROUP,
      targetId: userGroup.id
    });
  });
  memo.targetUserIds.map((user) => {
    shareInfo.push({
      targetType: SHARE_SETTING_TYPE_MARK.USER,
      targetId: user.id
    });
  });
  let shareStatus: UIShareStatusShape = {
    guid: memo.guid,
    createTimestamp: memo.createTimestamp,
    modifyTimestamp: memo.modifyTimestamp,
    shareStatus: shareInfo
  };
  var memoSyncRequest: ShareSyncRequestShape = {
    syncType: 0,
    guid: memo.guid,
    sourceShare: shareStatus
  };

  var myInit = Object.assign({}, DEFAULT_REQUEST_OPTIONS, {
    method: 'PUT',
    body: JSON.stringify(memoSyncRequest)
  });

  return oidcFetch(`${wmServerAddress}/share/memo/sync`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function remoteMemoContentSync(oidcFetch: Function, memo: UIMemoShape, memoContent: string) {
  var memoSyncRequest: MemoSyncRequestShape = {
    clientId: CLIENT_ID,
    syncType: 0,
    GUID: memo.guid,
    sourceMemo: memo,
    sourceMemoContent: memoContent
  };

  var myInit = Object.assign({}, DEFAULT_REQUEST_OPTIONS, {
    method: 'PUT',
    body: JSON.stringify(memoSyncRequest)
  });

  return oidcFetch(`${wmServerAddress}/memo/content/sync`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchRepresentation(oidcFetch: Function, memoId: string) {
  return oidcFetch(`${wmServerAddress}/representation/${memoId}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchTag(oidcFetch: Function, memoId: string) {
  return oidcFetch(`${wmServerAddress}/tag/${memoId}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchTraining(oidcFetch: Function, memoId: string) {
  return oidcFetch(`${wmServerAddress}/training/${memoId}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchRunTraining(oidcFetch: Function, trainingRequest: any) {
  var myInit = Object.assign({}, DEFAULT_REQUEST_OPTIONS, {
    method: 'POST',
    body: JSON.stringify(trainingRequest)
  });

  return oidcFetch(`${wmServerAddress}/training/run/memo`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}
