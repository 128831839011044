import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './app/store';

import { DEFAULT_LANGUAGE } from './common/MetaSetting';

import * as locales from '@mui/material/locale';
import { MEMO_PAGE_LIST_PANEL, MEMO_PAGE_VIEW_PANEL } from './common/MetaData';
import { MessageShape } from './model/AppUIShapes';
import { APPEND_MESSAGE } from './home/constants/ReduxConst';
import getNewGUIDString from './util/GUID';
type SupportedLocales = keyof typeof locales;

export interface HomeState {
  currentLanguage: SupportedLocales;
  tinyMceLang: string;
  currentListPanel: number;
  currentViewPanel: number;
  messages: MessageShape[];
  status: 'idle' | 'loading' | 'failed';
  isSyncing: boolean;
}

const initialState: HomeState = {
  currentLanguage: DEFAULT_LANGUAGE,
  tinyMceLang: 'zh-Hans',
  currentListPanel: MEMO_PAGE_LIST_PANEL.SECTION,
  currentViewPanel: MEMO_PAGE_VIEW_PANEL.EDIT,
  messages: [],
  status: 'idle',
  isSyncing: false
};

export const appendMessageAsync = createAsyncThunk<number, any, { state: RootState }>(APPEND_MESSAGE, async (param, thunkAPI) => {
  console.log('param', param);
  let message: MessageShape = {
    guid: getNewGUIDString(),
    messageType: param.messageType,
    message: param.message
  };
  thunkAPI.dispatch(appendMessageToLocal(message));

  return 0;
});

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeCurrentListPanel: (state, action: PayloadAction<number>) => {
      state.currentListPanel = action.payload;
    },
    changeCurrentViewPanel: (state, action: PayloadAction<number>) => {
      state.currentViewPanel = action.payload;
    },
    changeCurrentLanguage: (state, action: PayloadAction<SupportedLocales>) => {
      state.currentLanguage = action.payload;
      state.tinyMceLang = action.payload === 'zhCN' ? 'zh-Hans' : 'en';
    },
    appendMessageToLocal: (state, action: PayloadAction<MessageShape>) => {
      state.messages = [...state.messages, action.payload];
    },
    removeMessageFromLocal: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.filter((m) => m.guid !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(appendMessageAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(appendMessageAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(appendMessageAsync.rejected, (state) => {
        console.log('appendMessageAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      });
  }
});

export const { changeCurrentListPanel, changeCurrentViewPanel, changeCurrentLanguage, appendMessageToLocal, removeMessageFromLocal } = appSlice.actions;

export const selectCurrentLanguage = (state: RootState) => state.app.currentLanguage;
export const selectTinyMceLang = (state: RootState) => state.app.tinyMceLang;
export const selectCurrentListPanel = (state: RootState) => state.app.currentListPanel;
export const selectCurrentViewPanel = (state: RootState) => state.app.currentViewPanel;
export const selectMessages = (state: RootState) => state.app.messages;

export default appSlice.reducer;
