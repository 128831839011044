import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';

import { VIEW_LOAD_MEMO } from '../../constants/ReduxConst';
import { fetchMemoViewByMemoId } from './memoViewAPI';
import { FetchResponseShape } from '../../../model/MemoModelShapes';
import { HttpResponseType, MessageType } from '../../../model/AppUIShapes';
import { appendMessageAsync } from '../../../appSlice';
import { retryInterval } from '../../../common/MetaSetting';

export interface LocalUserState {
  status: 'idle' | 'loading' | 'failed';
  currentViewMemoStatus: number;
  currentViewMemo: any | undefined;
}

const initialState: LocalUserState = {
  status: 'idle',
  currentViewMemoStatus: 3,
  currentViewMemo: undefined
};

const fetchMemoViewByMemoIdRetry = (oidcFetch: Function, memoId: string, thunkAPI: any) => {
  fetchMemoViewByMemoId(oidcFetch, memoId).then((response: FetchResponseShape) => {
    if (response.responseType === HttpResponseType.SUCCESS) {
      response.content.then((content: any) => {
        if (content) {
          thunkAPI.dispatch(saveViewMemoStatusToLocal(content.requestResult));
          thunkAPI.dispatch(saveViewMemoToLocal(content.result));
        }
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: response.message }));
      setTimeout(() => fetchMemoViewByMemoIdRetry(oidcFetch, memoId, thunkAPI), retryInterval);
    }
  });
};

export const fetchViewMemoAsync = createAsyncThunk<number, any, { state: RootState }>(VIEW_LOAD_MEMO, async (param, thunkAPI) => {
  fetchMemoViewByMemoIdRetry(param.oidcFetch, param.memoId, thunkAPI);

  return 0;
});

export const viewMemoSlice = createSlice({
  name: 'viewMemo',
  initialState,
  reducers: {
    saveViewMemoStatusToLocal: (state, action: PayloadAction<number>) => {
      state.currentViewMemoStatus = action.payload;
    },
    saveViewMemoToLocal: (state, action: PayloadAction<any>) => {
      state.currentViewMemo = Object.assign({}, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchViewMemoAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchViewMemoAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(fetchViewMemoAsync.rejected, (state) => {
        console.log('fetchViewMemoAsync.rejected');
        state.status = 'failed';
      });
  }
});

export const { saveViewMemoStatusToLocal, saveViewMemoToLocal } = viewMemoSlice.actions;
export const selectCurrentViewMemoStatus = (state: RootState) => state.viewMemo.currentViewMemoStatus;
export const selectCurrentViewMemo = (state: RootState) => state.viewMemo.currentViewMemo;

export default viewMemoSlice.reducer;
