import React, { useState, useEffect } from 'react';

import { Button } from '@mui/material';

import { ArrowCircleUp } from '@mui/icons-material';
import { uploadAvatar } from './uploadAPI';

import { useAppDispatch } from '../../../app/hooks';
import { useOidcFetch } from '@axa-fr/react-oidc';
import { FetchResponseShape } from '../../../model/MemoModelShapes';
import { appendMessageAsync } from '../../../appSlice';
import { retryInterval } from '../../../common/MetaSetting';
import { HttpResponseType, MessageType } from '../../../model/AppUIShapes';

const UploadAvatar = (props: any) => {
  const [uploadDisable, setUploadDisable] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  let { targetUrl, fileUploadedHandler, buttonText } = props;

  const uploadClick = () => {
    if (uploadDisable) return;

    setUploadDisable(true);
  };

  const { fetch } = useOidcFetch();

  const uploadAvatarRetry = (oidcFetch: Function, url: string, file: any) => {
    uploadAvatar(oidcFetch, url, file).then((uploadResponse: FetchResponseShape) => {
      if (uploadResponse.responseType === HttpResponseType.SUCCESS) {
        uploadResponse.content.then((responseContent: any) => {
          fileUploadedHandler(responseContent);
        });
        dispatch(appendMessageAsync({ messageType: MessageType.SUCCESS, message: 'messageIdUpdateSuccess' }));
      } else {
        dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: uploadResponse.message }));
        setTimeout(() => uploadAvatarRetry(oidcFetch, url, file), retryInterval);
      }
    });
  };

  const fileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];
    for (let i = 0; i < files.length; i++) {
      uploadAvatarRetry(fetch, targetUrl, files[i]);
      setUploadDisable(false);
      break;
    }
  };

  return (
    <Button variant="contained" component="label" color="primary" onClick={uploadClick}>
      <ArrowCircleUp /> {buttonText}
      <input type="file" hidden onChange={fileChange} />
    </Button>
  );
};

export default UploadAvatar;
