export const VIEW_PAGE_STATUS = {
  UNKNOWN: 0,
  PROCESS: 1,
  ERROR: 2,
  EXCEPTION: 3
};

export const CommandType = {
  UNKNOWN: 0,
  EXE: 1,
  ANALYSIS: 2
};

export const HttpResponseErrorId = {
  UNKNOWN: 'errorIdUnknown',
  EXCEPTION: 'errorIdException'
};

export const MessageType = {
  UNKNOWN: 0,
  SUCCESS: 1,
  INFO: 2,
  WARNING: 3,
  ERROR: 4
};

export type MessageShape = {
  guid: string;
  messageType: number;
  message: string;
};

export const HttpResponseType = {
  UNKNOWN: 0,
  SUCCESS: 1,
  NO_AUTH: 2,
  ERROR: 3
};
