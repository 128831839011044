import React, { useRef, useEffect, useMemo, useState } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { useOidc, useOidcAccessToken, useOidcFetch } from '@axa-fr/react-oidc';

import { useAppDispatch, useAppSelector } from './app/hooks';
import { SecureDashboard, SecureEditPage, SecureUserGroupPage, SecureAccountPage } from './home/pages';

import { ROUTES } from './home/constants';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { fetchAllSectionsAsync, fetchAllSectionSharesAsync } from './home/components/section/sectionSlice';
import { fetchAllGroupsAsync, fetchAllGroupSharesAsync } from './home/components/group/groupSlice';
import { refreshLocalMemoAsync, fetchAllMemosAsync, fetchAllMemoSharesAsync, refreshMemoTagAsync, refreshMemoTrainingAsync } from './home/components/memo/memoSlice';

import { fetchUserAsync } from './home/components/user/userSlice';
import { useTranslation } from 'react-i18next';

import * as locales from '@mui/material/locale';
import { defaultTheme, wmServerAddress } from './common/MetaSetting';
import { selectCurrentLanguage } from './appSlice';
import { fetchAllUserGroupsAsync } from './home/components/UserGroup/userGroupSlice';
import { NotFound } from './NotFound';
import { MLayout } from './home/shared/MLayout';
import { ALayout } from './home/shared/ALayout';
import { MemoView } from './home/components/MemoView/MemoView';
import { getEmptyPhrase, PhraseContentType, SocketMessageType, UpdateTarget, UpdateType } from './common/MetaData';
import { SnackbarProvider } from 'notistack';
import { savePhraseAsync } from './home/components/training/trainingSlice';
import { UIPhraseShape } from './model/MemoModelShapes';
import getNewGUIDString from './util/GUID';
import { EventSource } from 'eventsource';
import { CLIENT_ID } from './util/GUID';

const titleContainer = document.getElementById('title')!;

let eventSource: EventSource;

export const App = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const themeWithLocale = useMemo(() => createTheme(defaultTheme, locales[currentLanguage]), [currentLanguage, defaultTheme]);

  const { isAuthenticated } = useOidc();

  const { fetch } = useOidcFetch();

  const triggerSyncTasks = () => {
    if (isAuthenticated) {
      dispatch(fetchAllSectionsAsync(fetch));
      dispatch(fetchAllSectionSharesAsync(fetch));
      dispatch(fetchAllGroupsAsync(fetch));
      dispatch(fetchAllGroupSharesAsync(fetch));
      dispatch(fetchAllMemosAsync(fetch));
      dispatch(fetchAllMemoSharesAsync(fetch));
      dispatch(fetchAllUserGroupsAsync(fetch));
    }
  };

  const initEventSource = () => {
    eventSource = new EventSource(`${wmServerAddress}/sse/${CLIENT_ID}`, {
      fetch: (input: any, init: any) => fetch(input, init)
    });

    eventSource.addEventListener('open', (event: any) => {
      console.log('event', event);
    });
    eventSource.addEventListener('message', (event: any) => {
      console.log('event', event);
      handleMessage(event.data);
    });
    eventSource.addEventListener('error', (event: any) => {
      console.log('event', JSON.stringify(event));
    });
    eventSource.addEventListener('update', (event: any) => {
      console.log('event', event);
    });
    eventSource.addEventListener('notice', (event: any) => {
      console.log('event', event);
    });
    eventSource.addEventListener('customEvent', (event: any) => {
      console.log('event', event);
    });
  };

  const handleMessage = (data: any) => {
    console.log('data', data);
    if (data) {
      const esMessage = JSON.parse(data);
      // console.log('esMessage', esMessage);
      if (esMessage.messageType === SocketMessageType.MEMO_UPDATE) {
        let updateMessage = esMessage.messageContent;
        switch (updateMessage.target) {
          case UpdateTarget.SECTION:
            break;
          case UpdateTarget.GROUP:
            break;
          case UpdateTarget.MEMO:
            switch (updateMessage.type) {
              case UpdateType.UPDATE:
                // console.log('refreshLocalMemoAsync', updateMessage.guid);
                dispatch(
                  refreshLocalMemoAsync({
                    guid: updateMessage.guid,
                    modifyTimestamp: updateMessage.modifyTimestamp,
                    oidcFetch: fetch
                  })
                );
                break;
              default:
                break;
            }
            break;
          case UpdateTarget.MEMO_TAG:
            switch (updateMessage.type) {
              case UpdateType.UPDATE:
                // console.log('refreshMemoTagAsync', updateMessage.guid);
                dispatch(
                  refreshMemoTagAsync({
                    guid: updateMessage.guid,
                    modifyTimestamp: updateMessage.modifyTimestamp,
                    oidcFetch: fetch
                  })
                );
                break;
              default:
                break;
            }
          case UpdateTarget.MEMO_TRAINING:
            switch (updateMessage.type) {
              case UpdateType.UPDATE:
                console.log('refreshMemoTrainingAsync', updateMessage);
                dispatch(
                  refreshMemoTrainingAsync({
                    guid: updateMessage.guid,
                    modifyTimestamp: updateMessage.modifyTimestamp,
                    oidcFetch: fetch
                  })
                );
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      }
      if (esMessage.messageType === SocketMessageType.COMMAND_RESULT) {
        let commandResult = esMessage.messageContent;
        let newPhrase: UIPhraseShape = Object.assign(getEmptyPhrase(), { guid: getNewGUIDString(), contentType: PhraseContentType.OTHER, content: commandResult.commandResult });
        dispatch(savePhraseAsync(newPhrase));
      }
    }
  };

  useEffect(() => {
    i18n.changeLanguage('zh-CN');
    if (isAuthenticated) {
      dispatch(fetchUserAsync(fetch));
      initEventSource();
    }
    triggerSyncTasks();

    return () => {};
  }, []);

  titleContainer.innerHTML = t('webTitle');

  return (
    <ThemeProvider theme={themeWithLocale}>
      <SnackbarProvider maxSnack={5} dense>
        <Routes>
          <Route path={ROUTES.index} element={<Navigate to={ROUTES.dashboard} />} />
          <Route path={ROUTES.view} element={<ALayout />}>
            <Route index element={<Navigate to={ROUTES.memo} />} />
            <Route path={ROUTES.memo} element={<MemoView />} />
          </Route>
          <Route path={ROUTES.home} element={<MLayout />}>
            <Route index element={<Navigate to={ROUTES.dashboard} />} />
            <Route path={ROUTES.dashboard} element={<SecureDashboard />} />
            <Route path={ROUTES.edit} element={<SecureEditPage />} />
            <Route path={ROUTES.user_group} element={<SecureUserGroupPage />} />
            <Route path={ROUTES.account} element={<SecureAccountPage />} />
            {/* <Route path={ROUTES.training} element={<SecureLibraryPage />} /> */}
          </Route>
          <Route path={ROUTES.other} element={<NotFound />} />
        </Routes>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
