import React, { ReactNode, useState } from 'react';

import { styled } from '@mui/material/styles';

import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { drawerHeaderMinHeight } from '../../common/MetaSetting';

const headerMinHeight = drawerHeaderMinHeight;

interface ILayoutProps {
  children: NonNullable<ReactNode>;
}

export const LayoutMain = ({ children }: ILayoutProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Box component="main" sx={{ flexGrow: 1, width: '100%', backgroundColor: 'secondary.light' }}>
      <Box sx={{ minHeight: headerMinHeight as any }} />
      {children}
    </Box>
  );
};
