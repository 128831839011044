import React, { useState, useRef, useEffect } from 'react';

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Button,
  Avatar,
  ListItemAvatar,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Divider,
  Typography,
  Select,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  ListItemSecondaryAction
} from '@mui/material';

import { MoreHoriz, Star, Add, Delete, Edit, Share } from '@mui/icons-material';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';

import { selectGroups, selectCurrentGroup, changeSelectedGroupId, selectGroupShares, updateGroupAsync, selectedGroupId } from './groupSlice';

import { selectCurrentSection, selectSections, changeSelectedSectionId } from '../section/sectionSlice';

import { UIGroupShape } from '../../../model/MemoModelShapes';
import { MessageType } from '../../../model/AppUIShapes';
import { ConfirmationDialog } from '../../shared';
import getNewGUIDString from '../../../util/GUID';
import { findOneWMObjectFromAll, findOneTargetWithGUID, triggerEnterKeyFunction } from '../../../common/Utility';
import { DEFAULT_GROUP_GUID, DEFAULT_SECTION_GUID, leftListWidth, maxLengthGroupName } from '../../../common/MetaSetting';
import { emptyGroup, getEmptyGroup, getEmptyShare, MEMO_PAGE_LIST_PANEL, MEMO_PAGE_VIEW_PANEL, SHARE_TYPE_MARK } from '../../../common/MetaData';
import { transferToGroupByGroupIdAsync } from '../memo/memoSlice';
import { useTranslation } from 'react-i18next';
import { changeCurrentShare } from '../../shareSlice';
import { appendMessageAsync, changeCurrentListPanel, changeCurrentViewPanel } from '../../../appSlice';
import { useOidcFetch } from '@axa-fr/react-oidc';

export function GroupList(props: any) {
  const currentGroupId = useAppSelector(selectedGroupId);
  const groups = useAppSelector(selectGroups);
  const groupShares = useAppSelector(selectGroupShares);
  const sections = useAppSelector(selectSections);
  const dispatch = useAppDispatch();
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { fetch } = useOidcFetch();

  const [editingGroup, setEditingGroup] = useState<UIGroupShape>(emptyGroup);
  const currentGroup: any = useAppSelector(selectCurrentGroup);
  const [openEdit, setOpenEdit] = useState(false);
  const [sectionId, setSectionId] = React.useState('');

  const [editName, setEditName] = useState<string | undefined>('');

  const [openCreate, setOpenCreate] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMoreMenu = Boolean(anchorEl);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [moreId, setMoreId] = useState<string | undefined>(currentGroupId);

  const setCurrentEditingGroup = (group: UIGroupShape) => {
    setEditingGroup(group);
    setEditName(group.name);
    setSectionId(group.sectionId);
  };

  const handleCreateGroup = () => {
    let newGroup: UIGroupShape = Object.assign(getEmptyGroup(), { name: t('defaultGroupName') });
    setCurrentEditingGroup(newGroup);
    setOpenCreate(true);
    if (addButtonRef && addButtonRef.current) addButtonRef.current.blur();
  };

  const handleMoreOperationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    let tmpMoreId: string | null = event.currentTarget.getAttribute('data-item-guid');
    setMoreId(tmpMoreId ? tmpMoreId : '');

    let tmpEditName: string | null = event.currentTarget.getAttribute('data-item-group-name');
    setEditName(tmpEditName ? tmpEditName : '');
  };

  const handleGroupClick = (groupId: string) => () => {
    setMoreId(groupId);
    dispatch(changeCurrentViewPanel(MEMO_PAGE_VIEW_PANEL.EDIT));
    // if (currentGroup && groupId === currentGroup.guid) return;

    dispatch(changeCurrentListPanel(MEMO_PAGE_LIST_PANEL.MEMO));
    if (groupId === DEFAULT_GROUP_GUID) {
      dispatch(changeSelectedGroupId(DEFAULT_GROUP_GUID));
      return;
    }

    let selectedGroup = findOneWMObjectFromAll(groups, groupId);
    if (selectedGroup) {
      dispatch(changeSelectedGroupId(selectedGroup.guid));
    }
  };

  const handleShareClick = (groupId: any) => {
    setMoreId(groupId);
    let selectedGroup = findOneWMObjectFromAll(groups, groupId);
    if (groupId !== currentGroup?.guid) changeSelectedGroupId(groupId);

    setAnchorEl(null);
    let selectedGroupShare = findOneTargetWithGUID(groupShares, groupId);
    let currentShare = undefined;
    if (selectedGroupShare) {
      currentShare = Object.assign({}, selectedGroupShare, {
        typeMark: SHARE_TYPE_MARK.GROUP,
        title: selectedGroup?.name
      });
    } else {
      currentShare = Object.assign(getEmptyShare(), {
        typeMark: SHARE_TYPE_MARK.GROUP,
        title: selectedGroup?.name,
        guid: groupId
      });
    }
    // setOpenShare(true);

    dispatch(changeCurrentShare(currentShare));
    dispatch(changeCurrentViewPanel(MEMO_PAGE_VIEW_PANEL.SHARE));
  };

  const handleMoreMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    let group = findOneWMObjectFromAll(groups, moreId);
    if (group) {
      setCurrentEditingGroup(Object.assign({}, group));
      setOpenEdit(true);
    }
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setOpenConfirm(true);
  };

  const isCurrentGroupChanged = () => {
    return !(editingGroup.name === editName && editingGroup.sectionId === sectionId);
  };

  const cleanEditingGroup = () => {
    setEditingGroup(emptyGroup);

    setOpenEdit(false);
    setEditName('');
    setSectionId('');
  };

  const handleOnClose = () => {
    if (isCurrentGroupChanged()) {
      dispatch(appendMessageAsync({ messageType: MessageType.WARNING, message: t('warningMessageChangeNotSave') }));
      return;
    }

    setOpenCreate(false);
    setOpenEdit(false);
    cleanEditingGroup();
  };

  const handleEditGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditName(event.target.value);
  };

  const handleCreateKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    triggerEnterKeyFunction(event, handleCreate);
  };

  const handleEditKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    triggerEnterKeyFunction(event, handleEditSave);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
    cleanEditingGroup();
  };

  const handleCreate = () => {
    let newGroup: UIGroupShape = Object.assign(getEmptyGroup(), {
      guid: getNewGUIDString(),
      name: editName,
      sectionId: currentSection.guid
    });
    dispatch(updateGroupAsync({ oidcFetch: fetch, group: newGroup }));
    handleCreateClose();
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    cleanEditingGroup();
  };

  const handleEditSave = () => {
    if (!isCurrentGroupChanged()) return;

    dispatch(
      updateGroupAsync({
        oidcFetch: fetch,
        group: Object.assign(editingGroup, {
          name: editName,
          sectionId: sectionId
        })
      })
    );
    setOpenEdit(false);
    cleanEditingGroup();
    let section = findOneWMObjectFromAll(sections, sectionId);
    if (section) dispatch(changeSelectedSectionId(section.guid));
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setMoreId('');
    setEditName('');
    setSectionId('');
  };

  const handleConfirmDelete = (selectedId: string | undefined) => {
    if (selectedId) {
      let currentGroup = findOneWMObjectFromAll(groups, selectedId);
      if (currentGroup)
        dispatch(
          updateGroupAsync({
            oidcFetch: fetch,
            group: Object.assign({}, currentGroup, { stateCode: 0 })
          })
        );
      setOpenConfirm(false);
      setMoreId('');
      dispatch(transferToGroupByGroupIdAsync({ oidcFetch: fetch, groupId: selectedId }));
    } else {
      setOpenConfirm(false);
      setMoreId('');
    }
  };

  const handleSectionChange = (event: SelectChangeEvent) => {
    setSectionId(event.target.value);
  };

  const handleShareIconClick = (selectedId: string | undefined) => {
    setAnchorEl(null);
    let targetMemo = findOneWMObjectFromAll(groups, selectedId);
    if (targetMemo) {
      // setCurrentShareMemo(Object.assign({}, targetMemo.shareSetting));
      // setOpenShare(true);
    }
  };

  const currentSection: any = useAppSelector(selectCurrentSection);

  let listCount: number = 0;
  if (groups) listCount = groups.filter((item) => item.stateCode !== 0 && item.sectionId === currentSection.guid).length;

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            minWidth: 300,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              flexGrow: 1,
              fontWeight: 'bolder'
            }}
            component="span"
          >
            {t('titleGroups')} {'(' + listCount + ')'}
          </Typography>
          <IconButton color="primary" onClick={handleCreateGroup} ref={addButtonRef}>
            <Add />
          </IconButton>
        </Box>
        <Box className="children-content">
          <List sx={{ width: leftListWidth, bgcolor: 'background.paper' }} dense>
            {currentSection && currentSection.guid === DEFAULT_SECTION_GUID && (
              <ListItem key="default-guid" disablePadding>
                <ListItemButton
                  sx={{
                    padding: 0,
                    '&.Mui-selected': {
                      backgroundColor: 'primary.light',
                      color: 'primary.main',
                      fontWeight: 'bolder'
                    },
                    '&:hover': {
                      backgroundColor: 'primary.light',
                      color: 'primary.main'
                    }
                  }}
                  role={undefined}
                  onClick={handleGroupClick(DEFAULT_GROUP_GUID)}
                >
                  <ListItemAvatar
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      minWidth: 36
                    }}
                  >
                    <Star color="primary" fontSize="small" />
                  </ListItemAvatar>
                  <ListItemText id="default-group" primary={t('titleDefaultGroup')} secondary={<React.Fragment>{t('descriptionDefaultGroup')}</React.Fragment>} />
                </ListItemButton>
              </ListItem>
            )}
            {currentSection &&
              groups &&
              groups
                .filter((g) => g.stateCode !== 0 && g.sectionId === currentSection.guid)
                .map((uiGroup: UIGroupShape) => {
                  const labelId = `checkbox-list-label-${uiGroup.guid}`;
                  return (
                    <ListItem
                      key={uiGroup.guid}
                      sx={{
                        '&:hover .moreOperation': { visibility: 'inherit' }
                      }}
                      disablePadding
                    >
                      <ListItemButton
                        sx={{
                          padding: 0,
                          '&.Mui-selected': {
                            backgroundColor: 'primary.light',
                            color: 'primary.main',
                            fontWeight: 'bolder'
                          },
                          '&:hover': {
                            backgroundColor: 'primary.light',
                            color: 'primary.main'
                          }
                        }}
                        role={undefined}
                        // onClick={handleGroupClick(uiGroup.guid)}
                        selected={uiGroup.guid === moreId}
                      >
                        {/* <ListItemAvatar>
                          <Avatar variant="rounded">
                            <GroupWork />
                          </Avatar>
                        </ListItemAvatar> */}
                        <ListItemAvatar
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                            minWidth: 36,
                            '&:hover': { bgcolor: 'rgb(0 0 0 / 4%)' }
                          }}
                          // onClick={() => handleShareIconClick(uiGroup.guid)}
                          onClick={() => handleShareClick(uiGroup.guid)}
                        >
                          <Share
                            sx={{
                              color: uiGroup.hasShare ? 'primary.main' : 'secondary.main'
                            }}
                            fontSize="small"
                          />
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <IconButton
                            className="moreOperation"
                            sx={{ visibility: 'hidden' }}
                            data-item-guid={uiGroup.guid}
                            data-item-group-name={uiGroup.name}
                            edge="end"
                            onClick={handleMoreOperationClick}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </ListItemSecondaryAction>
                        <ListItemText
                          id={labelId}
                          primary={
                            <Typography sx={{ color: 'inherit', background: 'inherit', fontWeight: 'inherit' }} noWrap>
                              {uiGroup.name}
                            </Typography>
                          }
                          onClick={handleGroupClick(uiGroup.guid)}
                          secondary={<React.Fragment>{new Date(uiGroup.modifyTimestamp).toLocaleDateString()}</React.Fragment>}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
          </List>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMoreMenu}
        onClose={handleMoreMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem dense onClick={handleEdit}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>{t('buttonEdit')}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem dense onClick={handleDelete}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>{t('buttonDelete')}</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog open={openCreate} onClose={handleOnClose} fullWidth maxWidth="sm">
        <DialogTitle>{t('titleCreateGroup')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{/* Create a new group. */}</DialogContentText>
          <FormControl sx={{ p: 1, width: '100%' }} size="small">
            <TextField
              autoFocus
              id="createGroupName"
              label={t('labelName')}
              type="text"
              fullWidth
              variant="standard"
              value={editName}
              inputProps={{ maxLength: maxLengthGroupName }}
              onChange={handleEditGroupNameChange}
              onKeyDown={handleCreateKeyPress}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateClose} variant="outlined" color="error">
            {t('buttonCancel')}
          </Button>
          <Button onClick={handleCreate} variant="contained" color="success">
            {t('buttonCreate')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEdit} onClose={handleOnClose} fullWidth maxWidth="sm">
        <DialogTitle>{t('titleEditGroup')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{/* Update group. */}</DialogContentText>
          <Box>
            <FormControl sx={{ p: 1, width: '100%' }} size="small">
              <TextField
                autoFocus
                id="editName"
                label={t('labelName')}
                type="text"
                fullWidth
                variant="standard"
                value={editName}
                inputProps={{ maxLength: maxLengthGroupName }}
                onChange={handleEditGroupNameChange}
                onKeyDown={handleEditKeyPress}
              />
            </FormControl>
            <FormControl sx={{ p: 1, width: '100%' }} variant="standard" size="small">
              <InputLabel id="group-sectionId-label">{t('labelSection')}</InputLabel>
              <Select labelId="group-sectionId-label" id="group-sectionId" value={sectionId} label={t('labelSection')} onChange={handleSectionChange}>
                {sections
                  .filter((s) => s.stateCode !== 0)
                  .map((s) => (
                    <MenuItem dense key={s.guid} value={s.guid}>
                      {s.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} variant="outlined" color="error">
            {t('buttonCancel')}
          </Button>
          <Button onClick={handleEditSave} variant="contained" color="primary">
            {t('buttonSave')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        id="confirmDelete"
        keepMounted
        open={openConfirm}
        onClose={handleConfirmClose}
        onConfirm={handleConfirmDelete}
        keyValue={moreId}
        titleValue={t('titleDeleteGroup')}
        messageValue={t('warningMessageDeleteConfirm') + editName}
      />
    </Box>
  );
}
