import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

i18next.use(initReactI18next).use(LanguageDetector).use(Backend).init({
  detection: options,
  fallbackLng: "zh-CN",
});
