export const AC_SERVER_ADDRESS = 'https://api.ccdtn.com';
export const AC_SERVER_PORT = 8765;
export const acServerAddress = `${AC_SERVER_ADDRESS}:${AC_SERVER_PORT}`;

export const USER_SERVER_ADDRESS = 'https://api.ccdtn.com';
// export const USER_SERVER_ADDRESS = "http://localhost";
export const USER_SERVER_PORT = 8543;
export const userServerAddress = `${USER_SERVER_ADDRESS}:${USER_SERVER_PORT}`;

export const WM_SERVER_ADDRESS = 'https://api.wisememo.com';
// export const WM_SERVER_ADDRESS = 'http://localhost';
export const WM_SERVER_PORT = 8321;
export const wmServerAddress = `${WM_SERVER_ADDRESS}:${WM_SERVER_PORT}`;

// export const WS_SERVER_ADDRESS = "ws://localhost";
// export const WS_SERVER_PORT = 8321;
// export const wsServerAddress = `${WS_SERVER_ADDRESS}:${WS_SERVER_PORT}`;

export const hostServerAddress = 'https://web.wisememo.com';
// export const hostServerAddress = "http://localhost:5555";
export const viewServerAddress = 'https://view.wisememo.com';
// export const viewServerAddress = "http://localhost:5656";

export const DEFAULT_SECTION_GUID = '_';
export const DEFAULT_GROUP_GUID = '_';

export const SOURCE_ID = 'CCDTN';

export const DEFAULT_LANGUAGE = 'zhCN';

import { createTheme } from '@mui/material/styles';
export let defaultTheme = createTheme({
  palette: {
    primary: {
      light: '#FEF0EF',
      main: '#FB4E2B',
      dark: '#DE2904',
      contrastText: '#FFFFFF'
    },
    secondary: {
      light: '#EEEEEE',
      main: '#8E8E8E',
      dark: '#6D6D6D',
      contrastText: '#000000'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.9)',
      secondary: 'rgba(0, 0, 0, 0.6)'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderRadius: '0.5em'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderRadius: '0.5em',
          backgroundColor: 'transparent',
          '& .MuiTextField-root': { borderRadius: '0.9em' }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': { borderRadius: '0.9em' }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '0.5em'
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          borderRadius: '0.5em',
          backgroundColor: '#FFFFFF'
        }
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        inputRoot: {
          borderRadius: '0.5em'
        }
      }
    },
    MuiAccordion: {
      defaultProps: {
        square: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '0.9em'
        }
      }
    }
  }
});
// export let defaultTheme = createTheme(
//     {
//         palette: {
//             primary: {
//                 light: '#FEF0EF',
//                 main: '#0c2375',
//                 dark: '#002884',
//                 contrastText: '#fff',
//             },
//             secondary: {
//                 light: '#F5F5F5',
//                 main: '#8E8E8E',
//                 dark: '#ba000d',
//                 contrastText: '#000',
//             },
//         },
//     }
// );

export const drawerWidth = 180;
export const mainHeaderHeight = '40px';
export const mainFooterHeight = '30px';
export const mainContentMarginTop = '70px'; // mainHeaderHeight + mainFooterHeight
export const mainContentMarginTopSecondary = '50px'; // mainHeaderHeight + mainFooterHeight
export const mainContentMarginTopThird = '160px'; // mainHeaderHeight + mainFooterHeight + status bar height + textfiled height
export const drawerHeaderMinHeight = '48px!important';
export const toolBarPaddingLeftRight = '1em!important';
export const leftListWidth = '300px';
export const editTopStatusHeight = '30px';

export const delayUpdate = 2000;
export const retryInterval = 30000;

export const maxLengthSectionName = 30;
export const maxLengthGroupName = 30;
export const maxLengthMemoTitle = 50;
export const maxLengthUserGroupName = 30;
export const maxWidthSectionBreadcrumb = '210px';
export const maxWidthGroupBreadcrumb = '280px';
