import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import appSlice from '../appSlice';
import sectionSlice from '../home/components/section/sectionSlice';
import groupSlice from '../home/components/group/groupSlice';
import memoSlice from '../home/components/memo/memoSlice';
import userSlice from '../home/components/user/userSlice';
import shareSlice from '../home/shareSlice';
import userGroupSlice from '../home/components/UserGroup/userGroupSlice';
import memoViewSlice from '../home/components/MemoView/memoViewSlice';
import searchSlice from '../home/components/search/searchSlice';
import trainingSlice from '../home/components/training/trainingSlice';

export const store = configureStore({
  reducer: {
    app: appSlice,
    user: userSlice,
    share: shareSlice,
    section: sectionSlice,
    group: groupSlice,
    memo: memoSlice,
    search: searchSlice,
    userGroup: userGroupSlice,
    viewMemo: memoViewSlice,
    training: trainingSlice
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
