import { TokenRenewMode } from '@axa-fr/react-oidc';
import { acServerAddress } from './common/MetaSetting';

export const configuration = {
  configurationName: 'wmc-profile',
  client_id: 'wise-memo-react',
  redirect_uri: window.location.origin + '/authentication/callback',
  silent_redirect_uri: window.location.origin + '/authentication/silent-callback',
  silent_login_uri: window.location.origin + '/authentication/silent-login',
  scope: 'openid',
  authority: acServerAddress,
  // authority_time_cache_wellknowurl_in_second: 60* 60,
  refresh_time_before_tokens_expiration_in_second: 40,
  // service_worker_relative_url: "/OidcServiceWorker.js",
  // service_worker_only: false,
  storage: sessionStorage,
  // silent_login_timeout: 12000,
  // automaticSilentRenew: true,
  // monitor_session: true,
  // extras: { google: 'google' },
  revokeAccessTokenOnSignout: true,
  token_renew_mode: TokenRenewMode.access_token_invalid
};

export default configuration;

// const configuration = {
//   loadingComponent: ReactComponent, // you can inject your own loading component
//   sessionLostComponent: ReactComponent, // you can inject your own session lost component
//   authenticating: ReactComponent, // you can inject your own authenticating component
//   authenticatingErrorComponent: ReactComponent,
//   callbackSuccessComponent: ReactComponent, // you can inject your own call back success component
//   serviceWorkerNotSupportedComponent: ReactComponent, // you can inject your page that explains you require a more modern browser
//   onSessionLost: Function, // If set, "sessionLostComponent" is not displayed, and onSessionLost callback is called instead
//   configuration: {
//     client_id: String.isRequired, // oidc client id
//     redirect_uri: String.isRequired, // oidc redirect url
//     silent_redirect_uri: String, // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore sessions
//     silent_login_uri: String, // Optional, route that triggers the signin
//     silent_login_timeout: Number, // Optional, default is 12000 milliseconds
//     scope: String.isRequired, // oidc scope (you need to set "offline_access")
//     authority: String.isRequired,
//     storage: Storage, // Default sessionStorage, you can set localStorage, but it is less secure against XSS attacks
//     authority_configuration: {
//       // Optional for providers that do not implement OIDC server auto-discovery via a .wellknown URL
//       authorization_endpoint: String,
//       token_endpoint: String,
//       userinfo_endpoint: String,
//       end_session_endpoint: String,
//       revocation_endpoint: String,
//       check_session_iframe: String,
//       issuer: String,
//     },
//     refresh_time_before_tokens_expiration_in_second: Number, // default is 120 seconds
//     service_worker_relative_url: String,
//     service_worker_only: Boolean, // default false
//     service_worker_update_require_callback: (registration:any, stopKeepAlive:Function) => Promise<void>, // callback called when service worker need to be updated, you can take the control of the update process
//     extras: StringMap | undefined, // ex: {'prompt': 'consent', 'access_type': 'offline'} list of key/value that is sent to the OIDC server (more info: https://github.com/openid/AppAuth-JS)
//     token_request_extras: StringMap | undefined, // ex: {'prompt': 'consent', 'access_type': 'offline'} list of key/value that is sent to the OIDC server during token request (more info: https://github.com/openid/AppAuth-JS)
//     withCustomHistory: Function, // Override history modification, return an instance with replaceState(url, stateHistory) implemented (like History.replaceState())
//     authority_time_cache_wellknowurl_in_second: 60 * 60, // Time to cache in seconds of the openid well-known URL, default is 1 hour
//     authority_timeout_wellknowurl_in_millisecond: 10000, // Timeout in milliseconds of the openid well-known URL, default is 10 seconds, then an error is thrown
//     monitor_session: Boolean, // Add OpenID monitor session, default is false (more information https://openid.net/specs/openid-connect-session-1_0.html), if you need to set it to true consider https://infi.nl/nieuws/spa-necromancy/
//     onLogoutFromAnotherTab: Function, // Optional, can be set to override the default behavior, this function is triggered when a user with the same subject is logged out from another tab when session_monitor is active
//     onLogoutFromSameTab: Function, // Optional, can be set to override the default behavior, this function is triggered when a user is logged out from the same tab when session_monitor is active
//     token_renew_mode: String, // Optional, update tokens based on the selected token(s) lifetime: "access_token_or_id_token_invalid" (default), "access_token_invalid", "id_token_invalid"
//     logout_tokens_to_invalidate: Array<string>, // Optional tokens to invalidate during logout, default: ['access_token', 'refresh_token']
//   }.isRequired,
// };
