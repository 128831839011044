import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';
import { fetchSections, fetchSection, remoteSectionSync, fetchSectionShares, fetchSectionShare, remoteSectionShareSync } from './sectionAPI';
import { FetchResponseShape, UISectionShape, UIShareShape, UIShareStatusShape } from '../../../model/MemoModelShapes';
import { HttpResponseType, MessageType } from '../../../model/AppUIShapes';

import { SYNC_SECTION_SHARES, SYNC_SECTIONS, UPDATE_SECTION, UPDATE_SECTION_SHARE } from '../../constants/ReduxConst';
import { defaultSection, getEmptyShare, SHARE_SETTING_TYPE_MARK, SHARE_TYPE_MARK } from '../../../common/MetaData';
import { findOneWMObjectFromAll, getWMObjectsNeedSyncForSource } from '../../../common/Utility';
import { DEFAULT_SECTION_GUID, retryInterval } from '../../../common/MetaSetting';
import { appendMessageAsync } from '../../../appSlice';

export interface SectionState {
  sections: UISectionShape[];
  sectionShares: UIShareShape[];
  selectedSectionId: string;
  status: 'idle' | 'loading' | 'failed';
  // lastSyncTimestamp: number;
  isSyncing: boolean;
  currentSection: UISectionShape | undefined;
}

const initialState: SectionState = {
  sections: [],
  sectionShares: [],
  selectedSectionId: '',
  status: 'idle',
  // lastSyncTimestamp: 0,
  isSyncing: false,
  currentSection: undefined
};

const getSectionsNeedSyncForLocal = (localSectionList: UISectionShape[], remoteSectionList: UISectionShape[]) => {
  var result: UISectionShape[] = [];
  remoteSectionList.forEach((remoteSection) => {
    var filteredItems = localSectionList.filter((s) => s.guid === remoteSection.guid);
    if (filteredItems.length === 0 || (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < remoteSection.modifyTimestamp)) {
      result.push(remoteSection);
    }
  });
  // console.log("Section need update for local", result);
  return result;
};

const changeSectionShareFlag = (sections: UISectionShape[], section: UISectionShape, shareSetting: UIShareShape) => {
  var filteredSections = sections.filter((m) => m.guid !== shareSetting.guid);
  let bShare = shareSetting.isPublic || (shareSetting.targetUserGroupIds && shareSetting.targetUserGroupIds.length > 0) || (shareSetting.targetUserIds && shareSetting.targetUserIds.length > 0);
  var updatedSections = [
    ...filteredSections,
    Object.assign({}, section, {
      hasShare: bShare
    })
  ];
  return updatedSections;
};

const getSectionsNeedSyncForRemote = (localSectionList: UISectionShape[], remoteSectionList: UISectionShape[]) => {
  var result: UISectionShape[] = [];
  localSectionList.forEach((section) => {
    if (section.stateCode === 0) return;
    var filteredItems = remoteSectionList.filter((s) => s.guid === section.guid);
    if (filteredItems.length === 0 || (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < section.modifyTimestamp)) {
      result.push(section);
    }
  });
  // console.log("Section need update for remote", result);
  return result;
};

const fetchSectionShareRetry = (oidcFetch: Function, sectionShareId: string, thunkAPI: any) => {
  fetchSectionShare(oidcFetch, sectionShareId).then((sectionShareResponse: FetchResponseShape) => {
    if (sectionShareResponse.responseType === HttpResponseType.SUCCESS) {
      sectionShareResponse.content.then((content: any) => {
        thunkAPI.dispatch(saveRemoteSectionShareToLocal(content));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: sectionShareResponse.message }));
      setTimeout(() => fetchSectionShareRetry(oidcFetch, sectionShareId, thunkAPI), retryInterval);
    }
  });
};

const remoteSectionShareSyncRetry = (oidcFetch: Function, sectionShareId: string, thunkAPI: any) => {
  let sectionShareLocal = findOneWMObjectFromAll(thunkAPI.getState().section.sectionShares, sectionShareId);
  remoteSectionShareSync(oidcFetch, sectionShareLocal).then((syncShareRespopnse: FetchResponseShape) => {
    if (syncShareRespopnse.responseType === HttpResponseType.SUCCESS) {
      syncShareRespopnse.content.then((sectionShareRemote: any) => {
        if (sectionShareRemote.syncResult === -1 && sectionShareRemote.syncedShare) thunkAPI.dispatch(saveRemoteSectionShareToLocal(sectionShareRemote.syncedShare));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: syncShareRespopnse.message }));
      setTimeout(() => remoteSectionShareSyncRetry(oidcFetch, sectionShareId, thunkAPI), retryInterval);
    }
  });
};

const syncAllSectionSharesRetry = (oidcFetch: Function, thunkAPI: any) => {
  let sectionShares = [...thunkAPI.getState().section.sectionShares];
  fetchSectionShares(oidcFetch, 0).then((response: FetchResponseShape) => {
    if (response.responseType === HttpResponseType.SUCCESS) {
      // console.log("Local section shares", state.section.sectionShares);
      // console.log("Remote section shares", remoteSectionShares);
      response.content.then((remoteSectionShares: any) => {
        var sectionSharesLocalNeedSync = getWMObjectsNeedSyncForSource(sectionShares, remoteSectionShares);
        sectionSharesLocalNeedSync.forEach((sectionShareLocal) => {
          fetchSectionShareRetry(oidcFetch, sectionShareLocal.guid, thunkAPI);
        });

        var sectionSharesRemoteNeedSync = getWMObjectsNeedSyncForSource(remoteSectionShares, sectionShares);
        sectionSharesRemoteNeedSync.forEach((sectionShareLocal) => {
          remoteSectionShareSyncRetry(oidcFetch, sectionShareLocal.guid, thunkAPI);
        });
        // console.log("Section share need update for remote", sectionSharesRemoteNeedSync);
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: response.message }));
      setTimeout(() => syncAllSectionSharesRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

const fetchSectionSharesRetry = (oidcFetch: Function, thunkAPI: any) => {
  fetchSectionShares(oidcFetch, 0).then((response: FetchResponseShape) => {
    if (response.responseType === HttpResponseType.SUCCESS) {
      // console.log("Local section shares", state.section.sectionShares);
      // console.log("Remote section shares", remoteSectionShares);
      response.content.then((remoteSectionShares: any) => {
        remoteSectionShares.forEach((sectionShareLocal: any) => {
          fetchSectionShareRetry(oidcFetch, sectionShareLocal.guid, thunkAPI);
        });
        // console.log("Section share need update for remote", sectionSharesRemoteNeedSync);
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: response.message }));
      setTimeout(() => fetchSectionSharesRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

export const fetchAllSectionSharesAsync = createAsyncThunk<number, Function, { state: RootState }>(SYNC_SECTION_SHARES, async (oidcFetch, thunkAPI) => {
  let currentTime = new Date().getTime();
  fetchSectionSharesRetry(oidcFetch, thunkAPI);

  return currentTime;
});

const remoteSectionSyncRetry = (oidcFetch: Function, sectionId: string, thunkAPI: any) => {
  let sectionLocal = findOneWMObjectFromAll(thunkAPI.getState().section.sections, sectionId);
  remoteSectionSync(oidcFetch, sectionLocal).then((sectionResponse: FetchResponseShape) => {
    if (sectionResponse.responseType === HttpResponseType.SUCCESS) {
      sectionResponse.content.then((content: any) => {
        if (content.syncResult === -1 && content.syncedSection) thunkAPI.dispatch(saveSectionToLocal(content.syncedSection));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: sectionResponse.message }));
      setTimeout(() => remoteSectionSyncRetry(oidcFetch, sectionId, thunkAPI), retryInterval);
    }
  });
};

const fetchSectionRetry = (oidcFetch: Function, sectionId: string, thunkAPI: any) => {
  fetchSection(oidcFetch, sectionId).then((sectionResponse: FetchResponseShape) => {
    if (sectionResponse.responseType === HttpResponseType.SUCCESS) {
      sectionResponse.content.then((content: any) => thunkAPI.dispatch(saveSectionToLocal(content)));
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: sectionResponse.message }));
      setTimeout(() => fetchSectionRetry(oidcFetch, sectionId, thunkAPI), retryInterval);
    }
  });
};

const syncAllSectionsRetry = (oidcFetch: Function, thunkAPI: any) => {
  let sections = [...thunkAPI.getState().section.sections];
  fetchSections(oidcFetch, 0).then((sectionsResponse: FetchResponseShape) => {
    if (sectionsResponse.responseType === HttpResponseType.SUCCESS) {
      sectionsResponse.content.then((remoteSections: any) => {
        var sectionsLocalNeedSync = getSectionsNeedSyncForLocal(sections, remoteSections);
        sectionsLocalNeedSync.forEach((sectionLocal) => {
          fetchSectionRetry(oidcFetch, sectionLocal.guid, thunkAPI);
        });

        var sectionsRemoteNeedSync = getSectionsNeedSyncForRemote(sections, remoteSections);
        sectionsRemoteNeedSync.forEach((sectionRemote) => {
          remoteSectionSyncRetry(oidcFetch, sectionRemote.guid, thunkAPI);
        });
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: sectionsResponse.message }));
      setTimeout(() => syncAllSectionsRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

const fetchSectionsRetry = (oidcFetch: Function, thunkAPI: any) => {
  fetchSections(oidcFetch, 0).then((sectionsResponse: FetchResponseShape) => {
    if (sectionsResponse.responseType === HttpResponseType.SUCCESS) {
      sectionsResponse.content.then((remoteSections: any) => {
        remoteSections.forEach((remoteSection: any) => {
          thunkAPI.dispatch(saveSectionToLocal(remoteSection));
        });
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: sectionsResponse.message }));
      setTimeout(() => fetchSectionsRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

export const fetchAllSectionsAsync = createAsyncThunk<number, Function, { state: RootState }>(SYNC_SECTIONS, async (oidcFetch, thunkAPI) => {
  let currentTime = new Date().getTime();
  fetchSectionsRetry(oidcFetch, thunkAPI);

  return currentTime;
});

export const updateSectionAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_SECTION, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();

  let sectionLocal = Object.assign({}, param.section, {
    modifyTimestamp: currentTime
  });
  if (sectionLocal.createTimestamp === 0) sectionLocal.createTimestamp = currentTime;
  thunkAPI.dispatch(saveSectionToLocal(sectionLocal));
  remoteSectionSyncRetry(param.oidcFetch, sectionLocal.guid, thunkAPI);

  return currentTime;
});

export const updateSectionShareAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_SECTION_SHARE, async (param, thunkAPI) => {
  const state = thunkAPI.getState();
  let currentTime = new Date().getTime();

  let newShare: any;
  let section = findOneWMObjectFromAll(state.section.sections, param.shareObject.guid);
  let shareSetting = findOneWMObjectFromAll(state.section.sectionShares, param.shareObject.guid);
  if (shareSetting) {
    newShare = Object.assign({}, shareSetting, param.shareObject.setting, {
      title: section?.name,
      createTimestamp: currentTime,
      modifyTimestamp: currentTime
    });
  } else {
    newShare = Object.assign({}, param.shareObject.setting, {
      title: section?.name,
      createTimestamp: currentTime,
      modifyTimestamp: currentTime
    });
  }

  thunkAPI.dispatch(saveEditSectionShareToLocal(Object.assign({}, param.shareObject, { newShare: newShare })));
  remoteSectionShareSyncRetry(param.oidcFetch, newShare.guid, thunkAPI);

  return currentTime;
});

export const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    changeSelectedSectionId: (state, action: PayloadAction<string>) => {
      if (state.selectedSectionId !== action.payload) {
        state.selectedSectionId = action.payload;
        let section = findOneWMObjectFromAll(state.sections, action.payload);
        if (section) {
          state.currentSection = Object.assign({}, section);
        } else {
          if (action.payload === DEFAULT_SECTION_GUID) {
            state.currentSection = defaultSection;
          } else {
            state.currentSection = undefined;
          }
        }
      }
    },
    saveEditSectionShareToLocal: (state, action: PayloadAction<any>) => {
      let section = findOneWMObjectFromAll(state.sections, action.payload.guid);
      if (section) {
        var updatedSections: any[] = changeSectionShareFlag(state.sections, section, action.payload.setting);
        state.sections = updatedSections.sort((a, b) => a.name.localeCompare(b.name));
      }

      var filteredItems = state.sectionShares.filter((m: any) => m.guid !== action.payload.guid);
      state.sectionShares = [...filteredItems, action.payload.newShare];
    },
    saveRemoteSectionShareToLocal: (state, action: PayloadAction<UIShareStatusShape>) => {
      let sectionShare = getEmptyShare();
      let section = findOneWMObjectFromAll(state.sections, action.payload.guid);
      sectionShare.title = section?.name;
      sectionShare.typeMark = SHARE_TYPE_MARK.SECTION;
      sectionShare.guid = action.payload.guid;
      sectionShare.createTimestamp = action.payload.createTimestamp;
      sectionShare.modifyTimestamp = action.payload.modifyTimestamp;
      let targetUserGroupIds: any[] = [];
      let targetUserIds: any[] = [];
      action.payload.shareStatus &&
        action.payload.shareStatus.forEach((shareInfo) => {
          if (shareInfo.targetType === SHARE_SETTING_TYPE_MARK.PUBLIC) sectionShare.isPublic = true;
          if (shareInfo.targetType === SHARE_SETTING_TYPE_MARK.USER_GROUP) targetUserGroupIds.push({ id: shareInfo.targetId, label: '' });
          if (shareInfo.targetType === SHARE_SETTING_TYPE_MARK.USER) targetUserIds.push({ id: shareInfo.targetId, label: '' });
        });
      sectionShare.targetUserGroupIds = targetUserGroupIds;
      sectionShare.targetUserIds = targetUserIds;
      var filteredItems = state.sectionShares.filter((m) => m.guid !== action.payload.guid);
      state.sectionShares = [...filteredItems, sectionShare];

      if (section) {
        var updatedSections: any[] = changeSectionShareFlag(state.sections, section, sectionShare);
        state.sections = updatedSections.sort((a, b) => a.name.localeCompare(b.name));
      }
    },
    saveSectionToLocal: (state, action: PayloadAction<UISectionShape>) => {
      var filteredItems = state.sections.filter((s) => s.guid !== action.payload.guid);
      var updatedAddedSections = [...filteredItems, Object.assign({}, action.payload)];
      let sectionShare = findOneWMObjectFromAll(state.sectionShares, action.payload.guid);
      if (sectionShare) {
        updatedAddedSections = changeSectionShareFlag(updatedAddedSections, action.payload, sectionShare);
      }
      state.sections = updatedAddedSections.sort((a, b) => a.name.localeCompare(b.name));

      if (state.selectedSectionId === action.payload.guid) {
        let section = findOneWMObjectFromAll(state.sections, action.payload.guid);
        state.currentSection = Object.assign({}, section);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSectionSharesAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(fetchAllSectionSharesAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(fetchAllSectionSharesAsync.rejected, (state) => {
        console.log('fetchAllSectionSharesAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(fetchAllSectionsAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(fetchAllSectionsAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(fetchAllSectionsAsync.rejected, (state) => {
        console.log('fetchAllSectionsAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(updateSectionAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(updateSectionAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(updateSectionAsync.rejected, (state) => {
        console.log('updateSectionAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(updateSectionShareAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(updateSectionShareAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(updateSectionShareAsync.rejected, (state) => {
        console.log('updateSectionShareAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      });
  }
});

export const { changeSelectedSectionId, saveEditSectionShareToLocal, saveRemoteSectionShareToLocal, saveSectionToLocal } = sectionSlice.actions;

export const selectedSectionId = (state: RootState) => state.section.selectedSectionId;
export const selectSections = (state: RootState) => state.section.sections;
export const selectSectionShares = (state: RootState) => state.section.sectionShares;
export const selectCurrentSection = (state: RootState) => state.section.currentSection;

export default sectionSlice.reducer;
